import React, {Component} from "react";

import 'react-toastify/dist/ReactToastify.css';
import {
    apiGetHeroSeasonHistory,
    apiGetHeroTokenUserData, apiGetTokenHeroData,
} from "./api/requests";
import {LoadingInline} from "./common/utility/loading";
import MapSeasonTokenHeroMintButton from "./MapSeasonTokenHeroMintButton";
import MapSeasonTokenHeroSellBackButton from "./MapSeasonTokenHeroSellBackButton";
import AdminWalletsModalWithAction from "./admin/AdminWalletsModalWithAction";
import units from "./units.json";
import {LuSwords} from "react-icons/lu";
import {FaCoins, FaShieldAlt} from "react-icons/fa";
import {GiWalkingBoot} from "react-icons/gi";
import heroes from "./heroes.json";
import LineChart from "./common/lineChart";

class MapSeasonTokenHeroElement extends Component {
    constructor(props) {
        super(props);

        const token = localStorage.getItem('authToken');

        const selfHero = heroes.filter(hero => hero.id === this.props.heroSeason.uid)[0];

        this.state = {
            loadingFromChain: true,
            loadingFromServer: true,
            loadingFromServerUserData: true,
            loadingHeroSeasonDataHistory: true,
            authToken: token,
            cardsMinted: 0,
            heroDataJson: selfHero,
            heroSeasonHistory: [],

        }

        this.loadCardDataFromServer = this.loadCardDataFromServer.bind(this);
        this.loadUserCardDataFromServer = this.loadUserCardDataFromServer.bind(this);
        this.loadCardDataFromChain = this.loadCardDataFromChain.bind(this);
        this.loadHeroSeasonHistoryData = this.loadHeroSeasonHistoryData.bind(this);


        this.potentialPrize = this.potentialPrize.bind(this);
        this.cardsMinted = this.cardsMinted.bind(this);
        this.potentialCardPrize = this.potentialCardPrize.bind(this);
        this.currentCardPrice = this.currentCardPrice.bind(this);
        this.chainButton = this.chainButton.bind(this);
        this.userHeroShards = this.userHeroShards.bind(this);
        this.currentSellCardPrice = this.currentSellCardPrice.bind(this);
        this.adminButton = this.adminButton.bind(this);


    }

    componentDidMount() {
        this.loadCardDataFromServer()
        this.loadCardDataFromChain()
        this.loadUserCardDataFromServer()
        this.loadHeroSeasonHistoryData()
    }

    async loadHeroSeasonHistoryData() {
        await apiGetHeroSeasonHistory(
            this.props.heroSeason.uid
        ).then(async (response) => {
            if (response.data !== undefined && response.data !== null) {
                this.setState({
                    heroSeasonData: response.data.rows,
                    loadingHeroSeasonDataHistory: false,
                })
            }
        }).catch(e => {
            this.setState({
                heroSeasonData: {},
                loadingHeroSeasonDataHistory: true,
            })
        })
    }

    async loadCardDataFromChain() {
        this.setState({
            loadingFromChain: false,
        })
    }

    async loadCardDataFromServer() {
        await apiGetTokenHeroData(
            this.props.heroSeason.uid,
            this.props.bucketId,
        ).then(async (response) => {
            if (response.hero !== undefined && response.hero !== null) {
                this.setState({
                    heroData: {
                        tokenContract: response.hero.tournament_contract,
                        cardPrice: this.props.currentPrice,
                        cardsMinted: response.total_supply,
                    },
                    loadingFromServer: false,
                })
            }
        }).catch(e => {
            this.setState({
                heroData: {

                }
            })
            this.setState({
                loadingFromServer: true,
            })
        })
    }

    async loadUserCardDataFromServer() {
        if(this.state.authToken === null) {
            this.setState({
                loadingFromServerUserData: true,
                userCardsMinted: 0,
            })
            return
        }
        await apiGetHeroTokenUserData(
            this.state.authToken,
            this.props.heroSeason.uid,
            this.props.bucketId
        ).then(async (response) => {
            if (response.token_amount !== undefined && response.token_amount !== null) {

                this.setState({
                    userCardsMinted: response.token_amount,
                    loadingFromServerUserData: false,
                    admin: response.admin !== undefined ? response.admin : null,
                })
            }
        }).catch(e => {
            this.setState({
                heroData: {
                    userCardsMinted: 0,
                }
            })
            this.setState({
                loadingFromServerUserData: true,
            })
        })


    }

    potentialPrize() {
        if(this.state.loadingFromServer) {
            return <LoadingInline />
        }
        return <>
            {parseFloat((this.props.prizePool * this.props.deductionPrizePool).toFixed(2))} <span className={"main-color-important"}>STRK</span>
        </>
    }

    cardsMinted() {
        if(this.state.loadingFromServer) {
            return <LoadingInline />
        }
        return <>
            {this.state.heroData.cardsMinted}
        </>
    }

    potentialCardPrize() {
        if(this.state.loadingFromServer === true || this.state.loadingFromChain === true) {
            return <LoadingInline />
        }
        const shardStartSaleDate = Date.parse(this.props.seasonConfig.hero_shards_start_sale)
        const seasonEndDate = Date.parse(this.props.seasonConfig.date_end)
        const seasonStartDate = Date.parse(this.props.seasonConfig.date_start)
        if(shardStartSaleDate < Date.now() && seasonEndDate > Date.now()) {
            if(this.state.heroData.cardsMinted === 0) {
                return <>
                    <span className={"main-color-important"}>Pot.prize/card:</span> N/A <span className={"main-color-important"}>STRK</span>
                </>
            }
            return <>
                <span className={"main-color-important"}>Pot.prize/card:</span> {(this.props.prizePool * this.props.deductionPrizePool / this.state.heroData.cardsMinted).toFixed(2)} <span className={"main-color-important"}>STRK</span>
            </>
        }

    }

    currentCardPrice() {
        if(this.state.loadingFromServer) {
            return <LoadingInline />
        }
        const shardStartSaleDate = Date.parse(this.props.seasonConfig.hero_shards_start_sale)
        const seasonEndDate = Date.parse(this.props.seasonConfig.date_end)
        const seasonStartDate = Date.parse(this.props.seasonConfig.date_start)
        if(shardStartSaleDate < Date.now() && seasonEndDate > Date.now()) {
            return <>
                <span
                    className={"main-color-important"}>Card price now:</span> {parseFloat(this.state.heroData.cardPrice).toFixed(2)} <span className={"main-color-important"}>STRK</span>
            </>
        }
    }

    currentSellCardPrice() {
        if(this.state.loadingFromServer) {
            return <LoadingInline />
        }
        const shardStartSaleDate = Date.parse(this.props.seasonConfig.hero_shards_start_sale)
        const seasonEndDate = Date.parse(this.props.seasonConfig.date_end)
        const seasonStartDate = Date.parse(this.props.seasonConfig.date_start)
        if(shardStartSaleDate < Date.now() && seasonEndDate > Date.now()) {
            return <>
                <span
                    className={"main-color-important"}>Sell price now:</span> {parseFloat(this.props.buyBackPrice).toFixed(2)} <span className={"main-color-important"}>STRK</span>
            </>
        }

    }

    adminButton() {
        if(this.state.loadingFromServer) {
            return <></>
        }
        if(this.state.admin === true) {
            return <div className={"pe-3"}>
                <AdminWalletsModalWithAction
                    label={"Admin panel"}
                    header={this.props.heroSeason.username + " (admin panel)"}
                    contractAddress={this.state.heroData.tokenContract}
                    id={this.props.seasonConfig.chain_tournament_id}
                    token={this.props.heroSeason.uid}
                    bucketId={this.props.seasonConfig.id}
                    mapStatic={this.props.seasonConfig}
                />
            </div>
        }
    }

    chainButton() {
        if(this.state.loadingFromServer) {
            return <LoadingInline />
        }

        const shardStartSaleDate = Date.parse(this.props.seasonConfig.hero_shards_start_sale)
        const seasonEndDate = Date.parse(this.props.seasonConfig.date_end)
        const seasonStartDate = Date.parse(this.props.seasonConfig.date_start)

        if(shardStartSaleDate < Date.now() && seasonEndDate > Date.now()) {
            return <>
                <div className={"pe-3"}>
                    <MapSeasonTokenHeroMintButton
                        contractAddress={this.state.heroData.tokenContract}
                        id={this.props.seasonConfig.chain_tournament_id}
                        token={this.props.heroSeason.uid}
                    />
                </div>
                <MapSeasonTokenHeroSellBackButton
                    contractAddress={this.state.heroData.tokenContract}
                    id={this.props.seasonConfig.chain_tournament_id}
                    token={this.props.heroSeason.uid}
                />
            </>
        }
        if(seasonEndDate < Date.now()) {
            return <>

            </>
        }

        return <>

        </>
    }

    userHeroShards() {
        if(this.state.authToken === null) {
            return <></>
        }
        if(this.state.loadingFromServer === true || this.state.loadingFromServerUserData === true) {
            return <LoadingInline />
        }
        if(this.state.heroData.cardsMinted === 0) {
            return <>
                <p className={"mb-0"}><span
                    className={"main-color-important"}>My cards:</span> 0</p>
                <p className={"mb-0"}><span
                    className={"main-color-important"}>My pot.prize:</span> N/A <span className={"main-color-important"}>STRK</span></p>
            </>
        }
        return <>
            <p className={"mb-0"}><span
                className={"main-color-important"}>My cards:</span> {this.state.userCardsMinted}</p>
            <p className={"mb-0"}><span
                className={"main-color-important"}>My pot.prize:</span> {parseFloat((this.state.userCardsMinted * this.props.prizePool * this.props.deductionPrizePool / this.state.heroData.cardsMinted).toFixed(2))} <span className={"main-color-important"}>STRK</span></p>


        </>
    }

    renderHeroArmy() {
        const { heroDataJson } = this.state;
        if (!heroDataJson || !heroDataJson.army) {
            return null;
        }

        const armyElements = Object.entries(heroDataJson.army).map(([unitId, quantity]) => {
            const unit = units.find(u => u.id === unitId);

            if (!unit || !unit.unitName || !unit.imageUrl) {
                return null;
            }

            return (
                <div key={unitId} className="army-unit">
                    <div className="army-unit-image-wrapper">
                        <img src={unit.imageUrl} alt={unit.unitName} className="army-unit-image" />
                        <div className="army-unit-quantity">х{quantity}</div>
                    </div>
                    <div className="army-unit-name">{unit.unitName}</div>
                </div>
            );
        });

        return (
            <div className="hero-army d-flex flex-row mt-1">
                {armyElements}
            </div>
        );
    }

    renderStatsDetails() {
        const { heroDataJson } = this.state;
        if (!heroDataJson || !heroDataJson.stats) {
            return null;
        }
        return (
            <div className="mt-1" >
                <div className=" bg-transparent text-white d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center me-2">
                        <LuSwords className="me-2" /> <strong>Attack:</strong>
                    </div>
                    <span>{heroDataJson.stats.Attack}</span>
                </div>
                <div className=" bg-transparent text-white d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center me-2">
                        <FaShieldAlt className="me-2" /> <strong>Defense:</strong>
                    </div>
                    <span>{heroDataJson.stats.Defence}</span>
                </div>
                <div className=" bg-transparent text-white d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center me-2">
                        <GiWalkingBoot className="me-2" /> <strong>Speed:</strong>
                    </div>
                    <span>{heroDataJson.stats.Speed}</span>
                </div>
                <div className="bg-transparent text-white d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center me-2">
                        <FaCoins className="me-2" /> <strong>Wealth:</strong>
                    </div>
                    <span>{heroDataJson.stats.Income}</span>
                </div>

            </div>
        );
    }

    heroSeasonHistory() {
        if(this.state.loadingHeroSeasonDataHistory) {
            return <LoadingInline />
        }
        return <div className={""}>
                <h3 className={"notransform-h3 pt-3"}>Places in recent tournaments</h3>
                <LineChart
                            data={this.state.heroSeasonData}
                           chartHeight={250}
                />
            </div>

    }

    heroScore() {
        if(this.props.heroSeason.points !== undefined && this.props.heroSeason.points !== null)
            return this.props.heroSeason.points
        else
            return 0
    }

    render() {
        return <>
            <div key={this.props.heroSeason.uid}>
                <h1 className="notransform-h2 main-color-important mb-2"
                    style={{fontSize: "2.5rem"}}>{this.props.heroSeason.username}</h1>
                <div className="mb-3 d-flex flex-row hero-element">
                    <div className={"d-flex flex-column align-content-center me-3 leaderboard-table-place"}>
                        <div><span>{this.props.heroSeason.place}</span></div>
                    </div>
                    <div className={"d-flex flex-column align-content-center me-3"}>
                        <img src={this.props.heroSeason.avatar}
                             className="hero-element-image rounded-5" alt={this.props.heroSeason.username}/>
                    </div>
                    <div>
                        <div className={"d-flex flex-row justify-content-between"}>
                            <div className={"d-flex flex-column"}>
                                <h3 className={""}>Tournament Data</h3>
                                <div className={"d-flex flex-row"}>
                                    <div className="text-white choose-hero-card-body pe-3">

                                        <p className={"mb-0"}><span
                                            className={"main-color-important"}>Score:</span> { this.props.noScore ? 0 : this.heroScore()}
                                        </p>
                                        <p className={"mb-0"}><span
                                            className={"main-color-important"}>Potential prize:</span> {this.potentialPrize()}
                                        </p>
                                        <p className={"mb-0"}><span
                                            className={"main-color-important"}>Cards minted:</span> {this.cardsMinted()}
                                        </p>
                                        {this.userHeroShards()}
                                    </div>

                                    <div className="text-white choose-hero-card-body">
                                        <p className={"mb-0"}>{this.currentCardPrice()}
                                        </p>
                                        <p className={"mb-0"}>{this.potentialCardPrize()}</p>
                                        <p className={"mb-0"}>{this.currentSellCardPrice()}</p>
                                    </div>
                                </div>
                                {this.heroSeasonHistory()}
                                <div>
                                    <div className="text-white choose-hero-card-body pe-3 pt-3 ">
                                        <div className={"d-flex flex-row flex-wrap"}>
                                            <div className={"me-5"}>
                                                <h3>Stats</h3>
                                                {this.renderStatsDetails()}
                                            </div>
                                            <div>
                                                <h3>Army</h3>
                                                {this.renderHeroArmy()}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className={"ms-auto d-flex flex-row  align-items-start flex-grow-1"}>
                        <div className={"ms-auto d-flex flex-row"}>
                            {this.adminButton()}
                            {this.chainButton()}
                        </div>
                    </div>
                </div>


            </div>
        </>
    }
}

export default MapSeasonTokenHeroElement;
