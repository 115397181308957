import React, {Component} from "react";
import {AccountInfoManager} from "./accountInfoManager";
import {delay} from "./common/utility/delay";
import {Link} from "react-router-dom";
import {apiGetMapScores} from "./api/requests";

class MapListElementLite extends Component{
    constructor(props) {
        super(props);

        const token = localStorage.getItem('authToken');

        this.state = {
            dateStart: (new Date(props.config.date_start)),
            dateEnd: new Date(props.config.date_end),
            canPlay: (new Date(props.config.date_start)) < (new Date()),
            showEnd: (new Date(props.config.date_start)) < (new Date()) && (new Date(props.config.date_end)) > (new Date()) && this.props.mapType === "season",
            showStart: (new Date(props.config.date_start)) > (new Date()) && this.props.mapType === "season",
            showEnded: (new Date(props.config.date_end)) < (new Date()) && this.props.mapType === "season",
            authToken: token,
            score: null,
            grandSeasonPoints: 0,
            mapType: this.props.mapType,
            achievements: null,
        }

        this.loadUserScore = this.loadUserScore.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);

    }

    componentDidMount() {
        if(this.state.canPlay)
            this.loadUserScore()
    }

    async loadUserScore() {
        if (this.state.authToken === null) {
            return
        }
        await apiGetMapScores(
            this.state.authToken, this.props.config.grand_season, this.props.config.id
        ).then(async (response) => {
            if (response.score !== undefined) {

                const getGrandSeasonPoints = (array,score) => {
                    let res = 0
                    for (let i = 0; i < array.length; i++) {
                        if(array[i].map_score === undefined)
                            continue
                        if(array[i].grand_season_bonus === undefined)
                            continue
                        if (score > array[i].map_score)
                            res += array[i].grand_season_bonus
                    }
                    return res
                };


                this.setState({
                    score: response.score.score,
                    grandSeasonPoints: getGrandSeasonPoints(this.props.config.grand_season_scores,  response.score.score)
                        + response.score.achievements.achievement_1 * this.props.config.achievement_1
                        + response.score.achievements.achievement_2 * this.props.config.achievement_2
                        + response.score.achievements.achievement_3 * this.props.config.achievement_3,
                    achievements: response.score.achievements,
                })
            }
        }).catch(e => {
            window.alerts.alert("internal error")
        })
    }

    async clickHideTutorial(hide) {

        const alert = window.alerts?.alert;
        const success = window.alerts?.success;
        const loading = window.alerts?.loading;
        const loadingStop = window.alerts?.loadingStop;
        const id = loading("Please wait...");

        const res = await AccountInfoManager.setHideTutorial(hide);
        if (res) {
            loadingStop(id, "Done");
            await delay(500)
            window.location.reload();
        }
        else {
            alert("Internal Server Error. Please refresh the page and try again.");
            await delay(500)
            window.location.reload();
        }

        return res;
    }

    render() {

        if (this.props.mapType === "campaign" && this.props.config.campaign !== 1) {
            return <></>
        }
        if (this.props.mapType === "season" && this.props.config.campaign === 1) {
            return <></>
        }

        return (
            <>
                <div className={"container-main ms-2 me-2"}>
                    <div className="mt-5 d-flex flex-md-row flex-column">
                        <div className="">
                            <div className={"card game-view-card"}>

                                <div className={"card-body"}>
                                      <div className={"m-3"}>
                                        {this.state.canPlay ? <Link to={"/play?map=" + this.props.config.id}>
                                            <img className={"img-fluid game-image rounded-3"}
                                                     src={this.props.config.image} alt="Force Prime Heroes"/>
                                            </Link> :
                                            <img className={"img-fluid game-image rounded-3"}
                                                 src={this.props.config.image} alt="Force Prime Heroes"/>
                                        }
                                    </div>
                                    {this.state.canPlay ? <Link to={"/play?map=" + this.props.config.id}
                                                                className="fp-btn-bg ">Play</Link> : <></>}

                                </div>
                            </div>
                        </div>
                        <div className="ms-2 block-panel-main game-info-right-part">
                            <div className="card-text white">

                                <p className="mb-4">
                                    {this.props.config.description.split('\n').map(function (item, key) {
                                        return (
                                            <span key={key}>
                                                {item}<br/>
                                                </span>
                                        )
                                    })}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

}

export default MapListElementLite;
