import Modal from 'react-bootstrap/Modal';
import React, {useState} from "react";
import heroes from "./heroes.json";
import {AccountInfoManager} from "./accountInfoManager";
import {delay} from "./common/utility/delay";
import {useNavigate} from "react-router-dom";


function ModalHeroChange({label, currentHero, mapId}) {

    const alert = window.alerts?.alert;
    const success = window.alerts?.success;
    const loading = window.alerts?.loading;
    const loadingStop = window.alerts?.loadingStop;

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate();

    async function setHeroOnMap(heroId) {
        while (true) {
            const id = loading("Please wait...");

            const res = await AccountInfoManager.setMapHero(mapId, heroId);

            if (res.res) {
                loadingStop(id, "Hero changed successfully");
                await delay(500)
                navigate(0);
                break
            } else {
                if (res.error.errorId === 10908) {
                    window.alerts.loadingStopFast(id, "Saving your result. Don't reload the page. Please wait a little longer.");
                    await delay(1000)
                } else {
                    window.alerts.alert("Internal Server Error. Please refresh the page and try again.");
                    break
                }
            }
        }
    }

    const heroesOn = heroes.filter(hero => hero.playable );

    return (
        <>
            <a className={"fp-btn-bg-small hero-element-btn  upper-menu-btn"} onClick={handleShow}>
                {label}
            </a>
            <Modal show={show} onHide={handleClose} dialogClassName="fp-modal-form">
                <Modal.Body>
                    <div className="d-flex flex-column user-box">
                        <div className={""}>
                            <h2 className={"notransform-h2 text-center"}>Switch Hero</h2>
                            <p className={"text-center text-warning"}>Warning: switching the hero will be equivalent to starting a new game</p>
                            {heroesOn.map((hero) => {
                                return <div key={hero.guid} className={"mb-3"}>
                                    <div key={hero.guid}
                                         className="mb-3 d-flex flex-row hero-element">
                                        <div className={"d-flex flex-column align-content-center me-3"}>
                                            <img src={hero.imageUrl}
                                                 className="hero-element-image rounded-5" alt={hero.heroName}/>
                                            {parseInt(currentHero) === parseInt(hero.id) ?
                                                <span className="badge bg-success">Selected</span> : <></>}
                                        </div>
                                        <div className="text-white choose-hero-card-body">
                                            <h5 className="main-color-important">{hero.heroName}</h5>
                                            <p className="">
                                                {hero.heroDescription.split('\n').map(function (item, key) {
                                                return (
                                                    <span key={key}>
                                                {item}<br/>
                                                </span>
                                                )
                                            })}

                                            </p>
                                        </div>
                                        <div className={"ms-auto d-flex flex-column justify-content-center"}>
                                            {parseInt(currentHero) === parseInt(hero.id) ?
                                                <button className="fp-btn-bg-small text-black d-none"
                                                        onClick={() => setHeroOnMap(hero.id)}>Choose
                                                </button> :
                                                <button className="fp-btn-bg-small text-black"
                                                        onClick={() => setHeroOnMap(hero.id)}>Choose
                                                </button>}
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalHeroChange;
