import React, {useEffect, useState} from 'react'
import {Account, cairo, CallData, Contract, RpcProvider, TRANSACTION_VERSION} from 'starknet';
import contract_abi from "../contract_abi.json";
import {apiAdminGetWalletHeroData, apiAdminGetWalletsData} from "../api/requests";
import {delay} from "../common/utility/delay";
import trimLeadingZeros, {shortenAddress} from "../common/wallets";
import heroes from "../heroes.json";
import AdminMapHeroPanelMintButton from "./AdminMapHeroPanelMintButton";
import './AdminMapHeroPanelWindow.css'; // Подключаем файл со стилями

function AdminMapHeroPanelWindow({contractAddress, id, token, bucketId, mapStatic}) {

    const alertPopup = window.alerts?.alert;
    const successPopup = window.alerts?.success;
    const loadingPopup = window.alerts?.loading;
    const loadingStopPopup = window.alerts?.loadingStop;
    const loadingAlertStopPopup = window.alerts?.loadingInAlertStop

    const [wallets, setWallets] = useState([]);  // создаем состояние для хранения данных
    const [loading, setLoading] = useState(true);

    const [heroData, setHeroData] = useState({})

    const authToken = localStorage.getItem('authToken');

    useEffect(() => {

        if(authToken === null || authToken === undefined || authToken === "") {
            return
        }

        // Эта функция будет выполнена при первом рендере компонента
        const fetchData = async () => {
            try {
                // Пример API запроса
                const response = await apiAdminGetWalletsData(authToken);
                if(response.error === true) {
                    alertPopup(response.errorDescription)
                    return
                }
                setWallets(response.wallets); // сохраняем данные в состояние
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);  // выключаем индикацию загрузки
            }
        };

        fetchData();  // вызов функции загрузки данных
    }, [id, token]);

    if(authToken === null || authToken === undefined || authToken === "") {
        return (<>Auth please</>)
    }

    if (loading) {
        return <div>Loading...</div>;  // пока данные загружаются, показываем индикатор
    }

    const showMintBtn = () => {
        const dateMintStart = Date.parse(mapStatic.hero_shards_start_sale)
        const dateEnd = Date.parse(mapStatic.date_end)
        if(Date.now() < dateEnd && Date.now() > dateMintStart) {
            return true
        } else {
            return false
        }
    }

    const showRewardBtn = () => {
        const dateEnd = Date.parse(mapStatic.date_end)
        if(Date.now() > dateEnd) {
            return true
        } else {
            return false
        }
    }

    const getRewardBuyBtn = async(accountAddress, privateKey) => {
        accountAddress = trimLeadingZeros(accountAddress)

        const provider = new RpcProvider({nodeUrl: String(process.env.REACT_APP_STARKNET_RPC_URL)});
        const account = new Account(provider, accountAddress, privateKey, undefined, "0x3");

        const tokenContract = new Contract(contract_abi, contractAddress, account)

        const idPopup = loadingPopup("Please wait...");

        const heroesOn = heroes.filter(hero => hero.playable );
        const uids = heroesOn.map((x, idx) => { return parseInt(x.id) });

        await account.execute([
            {
                contractAddress: "0x05235e5488f16e8bce172c281f2b12da33bf523b9ea359e2a93438f3b432372e",
                entrypoint: 'set_approval_for_all',
                calldata: CallData.compile({
                    operator: contractAddress,
                    approved: 1,
                }),
            },
            {
                contractAddress: contractAddress,
                entrypoint: 'claim_reward',
                calldata: CallData.compile({
                    id: id,
                    tokens: uids
                }),
            },
        ]).then(async (multiCall) => {
            console.log("tx hash", multiCall.transaction_hash)
            //successPopup("Transaction sent. Please wait for confirmation. " + multiCall.transaction_hash)
            await provider.waitForTransaction(multiCall.transaction_hash)
                .catch(e => {
                    loadingAlertStopPopup(idPopup,e)
                    console.log(e)
                }).finally(() => {
                    loadingStopPopup(idPopup,"Transaction sent. Please wait for confirmation. " + multiCall.transaction_hash)
                });
        }).catch(e => {
            loadingAlertStopPopup(idPopup, e.toString())
            console.log(e)
        });

    }

    const getHeroData = (wallet) => {
        if( wallet === undefined || wallet === null || wallet === "") {
            return <></>
        }
        if( heroData[wallet] !== undefined) { // если данные уже загружены
            return <>{parseFloat(heroData[wallet].strk / 1e18).toFixed(2)} ({heroData[wallet].token_amount})</>; // возвращаем их
        }
        return <></>
    }

    const getDataFromServer = async(wallet) => {
        // Your logic to get data from the server
        console.log("Getting data for wallet:", wallet);
        const popupId = loadingPopup("Please wait...");
        apiAdminGetWalletHeroData( authToken, token, bucketId, wallet).then( async (res) => {
            if(res.error === true) {
                alertPopup(res.errorDescription)
                return
            }
            console.log(res)
            loadingStopPopup(popupId, "Data fetched successfully")
            setHeroData(prevState => ({...prevState, [wallet]: res}));
        })
    }

    return (
        <div className={"d-flex flex-column align-content-center justify-content-center"}>
            {wallets.map((wallet, index) => (
                <>
                    <div key={index} className={"flex-row d-flex mt-2"}>

                        <div className={"align-content-center me-3"}><strong>{shortenAddress(wallet.account_address, 4,4)}</strong></div>
                        <div className={"align-content-center me-3"}><strong>{wallet.name}</strong></div>
                        <div className={"align-content-center"}><strong>{getHeroData(wallet.account_address)}</strong></div>
                        <div className={"ms-3 ms-auto d-flex flex-row"}>
                            <button
                                className="btn btn-success me-4 custom-btn"
                                onClick={() => getDataFromServer(wallet.account_address)}
                            >
                                Get data
                            </button>

                                {showRewardBtn() ? <button
                                        className="btn btn-success custom-btn"
                                        onClick={() => getRewardBuyBtn(wallet.account_address, wallet.private_key)}
                                    >
                                        Claim Reward
                                    </button>
                                    : <></> }
                            {showMintBtn() ?
                                <AdminMapHeroPanelMintButton
                                    contractAddress={contractAddress}
                                    id={id}
                                    token={token}
                                    accountAddress={wallet.account_address}
                                    privateKey={wallet.private_key}
                                />
                                : <></>}


                        </div>
                    </div>
                </>

            ))}
        </div>
    )
        ;
}

export default AdminMapHeroPanelWindow