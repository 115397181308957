function trimLeadingZeros(walletAddress) {
    if (walletAddress.startsWith("0x")) {
        let trimmedAddress = walletAddress.slice(2).replace(/^0+/, '');
        return '0x' + trimmedAddress;
    }
    return walletAddress;
}

export default trimLeadingZeros;

export function shortenAddress(address, startLength = 6, endLength = 4) {
    if (address.length <= startLength + endLength) {
        return address; // Если длина адреса меньше или равна сумме первых и последних символов, возвращаем адрес без изменений
    }
    const start = address.slice(0, startLength);
    const end = address.slice(-endLength);
    return `${start}...${end}`;
}

