import React, {Component} from "react";

import 'react-toastify/dist/ReactToastify.css';
import {
    apiGetHeroesTokenData,
    apiGetMapSeasonInfo, apiGetMapStaticSeasonConfig,
} from "./api/requests";
import {Loading, LoadingInline} from "./common/utility/loading";
import CountdownLabel from "./common/countdownLabel";
import IconTitleValue from "./common/iconTitleValue";
import heroes from "./heroes.json";
import MapSeasonTokenHeroElement from "./MapSeasonTokenHeroElement";
import MapSeasonTokenHeroRewardButton from "./MapSeasonTokenHeroRewardButton";
import {Link} from "react-router-dom";
import getHeroRewardPoolDeduction from "./heroes/heroRewardPoolDeduction";

class MapSeasonTokenHeroesPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingTokenData: true,
            loadingSeasonTop: true,
            loading: true,
            noSeason: false,
            isHero: 1,
            heroesOn: heroes.filter(hero => hero.playable ),
            mapId : this.props.search.get("map"),
            prizePool: 0,
            noScore: false,
        }
        this.loadSeasonTop = this.loadSeasonTop.bind(this);
        this.loadingMapStaticData = this.loadingMapStaticData.bind(this);
        this.loadHeroesTokenData = this.loadHeroesTokenData.bind(this);
        this.getHeroesPrize = this.getHeroesPrize.bind(this);

    }

    async componentDidMount() {
        await this.loadingMapStaticData();
        await this.loadSeasonTop()
        await this.loadHeroesTokenData();
    }

    async loadingMapStaticData() {
        await apiGetMapStaticSeasonConfig(
            this.state.mapId
        ).then(async (response) => {

            const getPlaces = (array) => {
                let res = {}
                for (let i = 0; i < array.length; i++) {
                    res[array[i].place] = array[i].score
                }
                return res
            };

            let placeConfig = response.data.config.place_scores;
            let placeConfigHeroes = response.data.config.hero_place_scores;

            this.setState({
                config: response.data.config,
                placesScores: getPlaces(placeConfig),
                placesScoresHeroes: getPlaces(placeConfigHeroes),
            })

            this.setState({
                loading: false,
            })
        })
    }

    async loadHeroesTokenData() {
        await apiGetHeroesTokenData(this.state.config.id).then(async (response) => {
            /*
            {
  "buy_back_price": 0,
  "current_price": 0,
  "error": false,
  "prize_pool": 0,
  "success": true,
  "t": 1728412498,
  "total_supply": 0
}
             */
            this.setState({
                buyBackPrice: response.buy_back_price,
                currentPrice: response.current_price,
                prizePool: response.prize_pool,
                totalSupply: response.total_supply,
                loadingTokenData: false,
            })
        })
    }

    async loadSeasonTop() {
        await apiGetMapSeasonInfo(
            this.state.mapId,
            true,
        ).then(async (response) => {
            const shardStartSaleDate = Date.parse(response.map_static.config.hero_shards_start_sale)
            const seasonEndDate = Date.parse(response.map_static.config.date_end)
            const seasonStartDate = Date.parse(response.map_static.config.date_start)
            if  (Date.now() > shardStartSaleDate &&  Date.now() < seasonStartDate) {

                let players = await apiGetMapSeasonInfo(
                    this.state.mapId,
                    true,
                    1
                ).then(async (response) => {
                    this.setState({
                        noScore: true,
                    })
                    return response.leaderboard.users
                }).catch( e => {
                    console.log(e);
                    window.alerts.alert("internal error")
                })

                const ids = players.map(x => {return x.uid});

                let infos

                const heroesOn = heroes.filter(hero => hero.playable );
                infos = heroesOn.map((x, idx) => { return {uid: x.id, username: x.heroName, avatar: x.imageUrl} });
                infos = {users: infos}

                players = players.map((x, idx) => { return {...x, ...infos.users.find(y => y.uid == x.uid) }});
                console.log(players)

                let playersUid = players.map((x, idx) => { return parseInt(x.uid)});
                console.log("uids", playersUid)
                this.setState({
                    loadingSeasonTop: false,
                    playersHeroes: players,
                    mapStatic: response.map_static,
                })

            } else {
                let players = response.leaderboard.users;
                const ids = players.map(x => {return x.uid});

                let infos

                const heroesOn = heroes.filter(hero => hero.playable );
                infos = heroesOn.map((x, idx) => { return {uid: x.id, username: x.heroName, avatar: x.imageUrl} });
                infos = {users: infos}

                players = players.map((x, idx) => { return {...x, ...infos.users.find(y => y.uid == x.uid) }});
                console.log(players)

                let playersUid = players.map((x, idx) => { return parseInt(x.uid)});
                console.log("uids", playersUid)
                this.setState({
                    loadingSeasonTop: false,
                    playersHeroes: players,
                    mapStatic: response.map_static,
                })
            }

        }).catch(e => {
            console.log(e);
            window.alerts.alert("internal error")
        })
    }

    getHeroesPrize() {
        if(this.state.loadingTokenData === true) {
            return <LoadingInline />
        }
        return parseFloat(this.state.prizePool).toFixed(2)
    }

    render() {
        if (this.state.noSeason === true) {
            return <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                <div className="panel-row width100" style={{justifyContent: "space-between"}}>
                    <h3 className={"notransform-h3"}>We are currently doing an update, please try refreshing in 5
                        minutes!</h3>
                </div>
            </div>
        }
        if (this.state.loading === true || this.state.loadingTokenData === true || this.state.loadingSeasonTop === true) {
            return <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                <div className="panel-row width100" style={{justifyContent: "space-between"}}>
                    <h1 className={"notransform-h1"}>Loading data...</h1>
                </div>
                <Loading></Loading>

            </div>

        }


        if (this.state.mapStatic === undefined || this.state.mapStatic === null) {
            return <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                <div className="panel-row width100" style={{justifyContent: "space-between"}}>
                    <h1 className={"notransform-h1"}>Current Season Map</h1>
                </div>
                <div>
                    <p>Map not found</p>
                </div>
            </div>
        }

        let pageHeader = this.state.mapStatic.config.name;

        const shardStartSaleDate = Date.parse(this.state.mapStatic.config.hero_shards_start_sale)
        const seasonEndDate = Date.parse(this.state.mapStatic.config.date_end)
        const seasonStartDate = Date.parse(this.state.mapStatic.config.date_start)

        if (Date.now() < shardStartSaleDate) {
            return <></>
        }

        let rewardBtn = <></>
        let tournamentData = <>
            <div className="pt-3 pb-3">
                <div className="d-flex ">
                    <IconTitleValue
                        icon="prizePool.svg"
                        title="Heroes prize">
                        {parseFloat(this.state.prizePool).toFixed(2)} STRK
                    </IconTitleValue>
                    <IconTitleValue
                        icon="seasonEnd.svg"
                        title="Tournament ends in">
                        <CountdownLabel targetDate={new Date(this.state.config.date_end)}/>
                    </IconTitleValue>
                </div>
            </div>
        </>

        if (Date.now() < seasonStartDate) {
            tournamentData = <>
                <div className="pt-3 pb-3">
                    <div className="d-flex ">
                        <IconTitleValue
                            icon="prizePool.svg"
                            title="Heroes prize">
                            {parseFloat(this.state.prizePool).toFixed(2)} STRK
                        </IconTitleValue>
                        <IconTitleValue
                            icon="seasonEnd.svg"
                            title="Tournament starts in">
                            <CountdownLabel targetDate={new Date(this.state.config.date_start)}/>
                        </IconTitleValue>
                    </div>
                </div>
            </>
        }

        if (Date.now() > seasonEndDate) {
            rewardBtn = <MapSeasonTokenHeroRewardButton
                contractAddress={this.state.mapStatic.config.chain_tournament_contract}
                id={this.state.mapStatic.config.chain_tournament_id}
            />
            tournamentData = <>
                <div className="pt-3 pb-3">
                    <div className="d-flex ">
                        <IconTitleValue
                            icon="prizePool.svg"
                            title="Heroes prize">
                            {parseFloat(this.state.prizePool).toFixed(2)} STRK
                        </IconTitleValue>

                    </div>
                </div>
            </>;
        }

        let specialBtn = <></>
        if (Date.now() > seasonStartDate) {
            specialBtn = <>
                <a href={"/play?map=" + this.state.mapId} className={"ms-3 fp-btn-bg-small"}>Play</a>
                <Link to={"/map/top?map=" + this.state.mapId}
                      className="fp-btn-bg lite iconTitleTile align-content-end">Tournament Page</Link>
            </>
        }
        let prizeData = <><div>
            <p>99% of STRK tokens from minting cards go to the Heroes prize pool, continuously increasing
                it.</p>
            <p>Upon the completion of the tournament, the winning heroes will distribute the prizes to the holders of
                their cards.</p>
            <p>Prize pool distribution:</p>
            <ul>
                <li>1st place - 60%</li>
                <li>2nd place - 30%</li>
                <li>3rd place - 10%</li>
            </ul>
        </div></>
        if(this.state.mapId === "season_GS5_small_6") {
            prizeData = <><div>
                <p>99% of STRK tokens from minting cards go to the Heroes prize pool, continuously increasing
                    it.</p>
                <p>Upon the completion of the tournament, the winning heroes will distribute the prizes to the holders of
                    their cards.</p>
                <p>Prize pool distribution:</p>
                <ul>
                    <li>1st place - 60%</li>
                    <li>2nd place - 30%</li>
                    <li>8th place - 10%</li>
                </ul>
            </div></>
        }

        return (
            <>
                <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                    <div className="d-flex flex-row align-items-center">
                        <h1 className={"notransform-h1"}>{pageHeader}</h1>
                        {specialBtn}
                    </div>

                    {tournamentData}
                    {prizeData}
                    {rewardBtn}

                    <div className={" justify-content-around"}>
                        {this.state.playersHeroes.map((hero, k) => {
                            //if (k > 0)
                            //    return

                            return <div key={"hero" + k} className={"rounded p-3 mb-2 m-0 m-sm-2"}>
                                <MapSeasonTokenHeroElement
                                    totalSupply={this.state.totalSupply}
                                    bucketId={this.state.config.id}
                                    heroSeason={hero}
                                    k={k}
                                    prizePool={this.state.prizePool}
                                    deductionPrizePool={getHeroRewardPoolDeduction(this.state.config, hero.place)}
                                    buyBackPrice={this.state.buyBackPrice}
                                    currentPrice={this.state.currentPrice}
                                    seasonConfig={this.state.config}
                                    noScore={this.state.noScore}
                                />
                            </div>
                        })}
                    </div>

                </div>
            </>
        )
    }
}

export default MapSeasonTokenHeroesPage;
