import React, {Component} from "react";

import 'react-toastify/dist/ReactToastify.css';
import {
    apiGetMapSeasonInfo,
    apiGetMapStaticSeasonConfig,
    apiGetUserInfoMany,
} from "./api/requests";
import {Loading} from "./common/utility/loading";
import heroes from "./heroes.json";
import {Link} from "react-router-dom";
import MapSeasonTokenHeroesPanel from "./MapSeasonTokenHeroesPanel";
import MapListElementLite from "./MapListElementLite";

class MapTopPage extends Component {
    constructor(props) {
        super(props);

        const userId = localStorage.getItem('userId');

        this.state = {
            mapId : this.props.search.get("map"),
            loading: true,
            userId: userId,
            isHero: this.props.search.get("is_hero")
        }
        this.loadMapTop = this.loadMapTop.bind(this);
    }

    componentDidMount() {
        this.loadMapTop()
    }

    async loadMapTop() {
        await apiGetMapStaticSeasonConfig(
            this.state.mapId
        ).then(async (response) => {

            const getPlaces = (array) => {
                let res = {}
                for (let i = 0; i < array.length; i++) {
                    res[array[i].place] = array[i].score
                }
                return res
            };

            let placeConfig = response.data.config.place_scores;
            let placeConfigHeroes = response.data.config.hero_place_scores;

            this.setState({
                config: response.data.config,
                placesScores: getPlaces(placeConfig),
                placesScoresHeroes: getPlaces(placeConfigHeroes),
            })

            await apiGetMapSeasonInfo(
                this.state.mapId,
                null,
            ).then(async (response) => {
                console.log(response)
                //await delay(1000);

                let players = response.leaderboard.users;
                const ids = players.map(x => {return x.uid});

                let infos

                infos = await apiGetUserInfoMany(ids);

                players = players.map((x, idx) => { return {...x, ...infos.users.find(y => y.uid == x.uid) }});


                this.setState({
                    players: players,
                    mapStatic: response.map_static,
                })
            }).catch(e => {
                console.log(e);
                window.alerts.alert("internal error")
            })

            await apiGetMapSeasonInfo(
                this.state.mapId,
                true,
            ).then(async (response) => {
                console.log(response)
                //await delay(1000);

                let players = response.leaderboard.users;
                const ids = players.map(x => {return x.uid});

                let infos

                const heroesOn = heroes.filter(hero => hero.playable );
                console.log(heroesOn)
                infos = heroesOn.map((x, idx) => { return {uid: x.id, username: x.heroName, avatar: x.imageUrl} });
                infos = {users: infos}

                players = players.map((x, idx) => { return {...x, ...infos.users.find(y => y.uid == x.uid) }});
                console.log("asdasd")
                console.log(players)

                this.setState({
                    playersHeroes: players,
                })
            }).catch(e => {
                console.log(e);
                window.alerts.alert("internal error")
            })

            this.setState({
                loading: false,
            })
        })
    }

    render() {

            if(this.state.loading === true) {
                return <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                    <div className="panel-row width100" style={{justifyContent: "space-between"}}>
                        <h1 className={"notransform-h1"}>Current Season Map (loading)</h1>
                    </div>
                    <Loading></Loading>
                </div>

            }

            if(this.state.mapStatic === undefined || this.state.mapStatic === null) {
                return <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                    <div className="panel-row width100" style={{justifyContent: "space-between"}}>
                        <h1 className={"notransform-h1"}>Current Season Map</h1>
                    </div>
                    <div>
                        <p>Map not found</p>
                    </div>
                </div>
            }

            let otherTopLabel = "Heroes leaderboard"
            let otherTopLink = "/map/top?map="+this.state.mapId+"&is_hero=1"
            let playerField = "Player"
            if(this.state.isHero !== null) {
                otherTopLabel = "Player leaderboard"
                otherTopLink = "/map/top?map="+this.state.mapId
                playerField = "Hero"
            }

        return (
            <>
                <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                    <div className="d-flex flex-row align-items-center">
                        <h1 className={"notransform-h1"}>{this.state.mapStatic.config.name}</h1>
                    </div>
                    <MapListElementLite
                        config={this.state.mapStatic.config}
                        mapType={"season"}
                    />
                    <MapSeasonTokenHeroesPanel
                        mapId={this.state.mapId}
                    />

                    <h2 className={"notransform-h2 pt-3"}>Players</h2>
                    <p className={"pt-1"}>Please note that Season Points earned are awarded at the end of the
                        tournament.</p>
                    <p>You can change your nick on the leaderboard and start a new game in the PROFILE
                        tab.</p>
                    <table role="table" className="leaderboard-table" style={{minWidth: "0px"}}>
                        <thead>
                        <tr role="row" className={"table-row"}>
                            <th colSpan="1" role="columnheader"
                                className={"table-cell"}>
                                <div className="default-tile-header iconTitleTile-title">Place</div>
                            </th>
                            <th colSpan="1" role="columnheader"
                                className={"table-cell-th-username"}>
                                <div className="default-tile-header iconTitleTile-title">Player</div>
                            </th>
                            <th colSpan="1" role="columnheader"
                                className={"table-cell"}>
                                <div className="default-tile-header iconTitleTile-title">Score</div>
                            </th>
                            <th colSpan="1" role="columnheader"
                                className={"table-cell"}>
                                <div className="default-tile-header iconTitleTile-title">Season points</div>
                            </th>
                        </tr>
                        </thead>
                        <tbody role="rowgroup">

                        {this.state.players.map((v, k) => {
                            const selfClass = v.uid === this.state.userId ? " table-self-row" : ""
                            console.log(v, selfClass, this.state.userId)

                            return <tr key={v.uid} role="row" className={"table-row" + selfClass}>
                                <td role="cell"
                                    className={"table-cell"}>
                                    <div className="leaderboard-table-place">
                                        <div><span>{v.place}</span></div>
                                    </div>
                                </td>
                                <td role="cell"
                                    className={"table-cell-username"}> {v.username}
                                </td>
                                <td role="cell"
                                    className={"table-cell"}
                                    style={{alignSelf: "start"}}> {v.points}
                                </td>
                                <td role="cell"
                                    className={"table-cell"}
                                    style={{alignSelf: "start"}}> {this.state.placesScores[v.place] !== undefined ? this.state.placesScores[v.place] : ""}
                                </td>
                            </tr>
                        })}


                        </tbody>
                    </table>

                </div>
            </>
        )
    }
}

export default MapTopPage;
