import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './screensize.css';
import './games.css';
import './buttons.css';
import './animations.css';
import './common.css';
import './highlighter.css';
import './fonts.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import {ToastContainer} from "react-toastify";
import {BrowserRouter} from "react-router-dom";
import {CookiesProvider} from "react-cookie";
import {MaintenancePage} from "./MaintenancePage";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <CookiesProvider>
        <ToastContainer/>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
