import { delay } from "../common/utility/delay";

function RequestQueue() {

    const queue = [];
    let inRequest = false;

    processQueue();

    this.sendRequest = (uri, data, priority) => {
        return new Promise((resolve, reject)=> {
            const request = {uri, data, resolve, reject};
            if (!inRequest && queue.length == 0)
                processRequest(request);
            else
            {
                if (priority !== undefined && priority === true)
                    queue.push(request);
                else
                    queue.push(request);
            }
        });
    };

    async function doRequest(request) {
        while (true) {
            let res = undefined;

            try {
                res = await fetch(request.uri, request.data);
            } catch (err) {
                throw err;
            }
            
            if (!res.ok) {
                if (res.status === 403) {
                    throw {
                        status: res.status,
                        reLogin: true
                    }
                } else if (res.status === 429) {
                    await delay(5000);
                    continue;
                }
                throw new Error("HTTP request error: " + res.status)
            }
    
            const body = await res.json();

            if (!body.error) {
                return body;
            } else {
                if (Object.prototype.hasOwnProperty.call(body, "errorDescription") && body.errorDescription) {
                    throw body;
                } else {
                    throw new Error("Server request error");
                }
            }
        }
    }

    async function processRequest(request) {
        inRequest = true;

        let error = null;
        try {
            const res = await doRequest(request);
            request.resolve(res);
        } catch (err) {
            error = err;
            request.reject(err);
        } 

        inRequest = false;

        return error;
    }

    async function processQueue() {
        while (true) {
            await delay(300);

            if (queue.length > 0) {
                const error = await processRequest(queue[0]);

                queue.shift();

                if (error?.reLogin) {
                    clearQueue();
                }
            }
        }
    }

    function clearQueue() {
        queue.length = 0;
    }
}

export default RequestQueue;