import { Outlet, Link } from "react-router-dom";
import {Footer} from "./Footer";
import UpperPanel from "./UpperPanel";
import React from "react";


const CommonLayout = ({onClickWallet,onClickAnonymous,account, disconnectWallet, onSetWallet }) => {
  return (
      <>
          <UpperPanel onClickWallet={onClickWallet} onClickAnonymous={onClickAnonymous} account={account} disconnectWallet={disconnectWallet} onSetWallet={onSetWallet}/>
          <Outlet/>
          <Footer />
      </>
  )
};

export default CommonLayout;
