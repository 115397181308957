import React, {Component} from "react";
import {Link} from "react-router-dom";

class MapAchievementsElement extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        if(this.props.achievement === 0) {
            return <></>
        }

        return (
            <>
                <div>{this.props.achievement_text} : {this.props.isDone ? <>✓done</> : <>not done</>}</div>
            </>
        )
    }
};

export default MapAchievementsElement;
