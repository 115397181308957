import Modal from 'react-bootstrap/Modal';
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import AdminMapHeroPanelWindow from "./AdminMapHeroPanelWindow";


function AdminWalletsModalWithAction({label, header,  id, contractAddress, token, bucketId, mapStatic}) {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate();

    return (
        <>
            <button className={"fp-btn-bg-small text-black d-none d-sm-block"} onClick={handleShow}>
                {label}
            </button>
            <Modal show={show} onHide={handleClose} dialogClassName="fp-modal-form wide-modal">
                <Modal.Body>
                    <div className="d-flex flex-column user-box">
                        <div className={""}>
                            <h2 className={"notransform-h2"}>{header}</h2>
                            <h3 className={"notransform-h2 text-center"}>Choose wallet</h3>
                            <AdminMapHeroPanelWindow
                                contractAddress={contractAddress}
                                id={id}
                                token={token}
                                bucketId={bucketId}
                                mapStatic={mapStatic}
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AdminWalletsModalWithAction;
