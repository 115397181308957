import Modal from 'react-bootstrap/Modal';
import {useState} from "react";

function ModalHowToPlay({label, classNameType}) {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <a className={classNameType} onClick={handleShow}>
                {label}
            </a>
            <Modal show={show} onHide={handleClose} dialogClassName="fp-modal-form">
                <Modal.Body>
                    <div className="d-flex flex-column user-box">

                        <div className={""}>
                            <h3 className={"notransform-h3 "}>TL;DR</h3>
                            <ul>
                                <li>Gather gold from chests and mines, build up your army by recruiting units at towers,
                                    and defeat the final boss - the Bone Dragon - to claim victory
                                </li>
                            </ul>

                            <h3 className={"notransform-h3 "}>Controls</h3>
                            <ul>
                                <li>Move your hero using WASD or simply click/tap the tile you wish to move to</li>
                                <li>Use drag and drop to scroll the map</li>
                            </ul>

                            <h3 className={"notransform-h3 "}>Tips</h3>
                            <ul>
                                <li>Do not attack armies whose power is more than yours</li>
                                <li>To reduce your casualties, have more power</li>
                                <li>Hire ranged units to avoid casualties in battles against weak enemies</li>
                                <li>If you want to skip a day without taking action, just press "Next Day" button</li>
                            </ul>

                            <h3 className={"notransform-h3 "}>Buildings and Objects</h3>
                            <ul>
                                <li>Chest - gives 1000 gold once</li>
                                <li>Gold mine - gives 300 gold daily after capturing</li>
                                <li>Towers - allow to recruit troops; each tower has specific units available</li>
                                <li>Boss Castle - the final goal; capture it in less than map time limit to get extra score</li>
                            </ul>

                            <h3 className={"notransform-h3 "}>Units</h3>
                            <ul>
                                <li>In each tower, there is a different type of unit</li>
                                <li>Centaur | 6 power | 150 gold</li>
                                <li>Dwarf | 10 power | 250 gold | ranged</li>
                                <li>Crusader | 12 power | 350 gold</li>
                                <li>Monk | 18 power | 500 gold | ranged</li>
                                <li>Angel | 30 power | 800 gold</li>
                            </ul>

                            <h3 className={"notransform-h3 "}>Hero Stats (per level)</h3>
                            <ul>
                                <li>Attack: +8% to the attack of units</li>
                                <li>Defense: +10% to the HP of units</li>
                                <li>Speed: +20 daily move points</li>
                                <li>Wealth: +100 gold daily</li>
                            </ul>

                            <h3 className={"notransform-h3 "}>More Details</h3>
                            <ul>
                                <li>Here <a href={"https://fp-heroes.gitbook.io/how-to-play/"}
                                            target={"_blank"}>https://fp-heroes.gitbook.io/how-to-play/</a></li>
                            </ul>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalHowToPlay;
