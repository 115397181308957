import React from 'react'
import { useEffect, useState } from 'react'
import { connect, disconnect } from "starknetkit"
import {InjectedConnector} from "starknetkit/injected";
import {cairo, CallData, Contract, provider, WalletAccount} from 'starknet';
import contract_abi from "./contract_abi.json";

function MapSeasonTokenHeroSellBackButton({contractAddress, id, token}) {
    const [connection, setConnection] = useState('');
    const [account, setAccount] = useState('');
    const [address, setAddress] = useState('');

    const alert = window.alerts?.alert;
    const success = window.alerts?.success;
    const loading = window.alerts?.loading;
    const loadingStop = window.alerts?.loadingStop;

    useEffect(() => {
        const connectToStarknet = async() => {
            const { wallet } = await connect(
                {
                    modalMode: "neverAsk",
                    dappName: "FORCE PRIME HEROES",
                    connectors: [
                        new InjectedConnector({
                            options: {id: "argentX"}
                        }),
                        new InjectedConnector({
                            options: {id: "braavos"}
                        })
                    ]
                }
            )

            if (wallet && wallet.isConnected) {
                setConnection(wallet)
                setAccount(wallet.account)
                setAddress(wallet.selectedAddress)
            }
            await connectToStarknet().then((res) => {
                console.log(res)
            })
        }
    }, [])

    const connectWallet = async() => {
        const { wallet } = await connect(
            {
                dappName: "FORCE PRIME HEROES",
                connectors: [
                    new InjectedConnector({
                        options: {id: "argentX"}
                    }),
                    new InjectedConnector({
                        options: {id: "braavos"}
                    })
                ]
            }
        )
		
        if(wallet && wallet.isConnected) {
            setConnection(wallet)
            setAccount(wallet.account)
            setAddress(wallet.selectedAddress)
        }
    }

    const tokenBuyBtn = async() => {
        if (!connection) {
            await connectWallet()
        }
        const {wallet} = await connect(
            {
                modalMode: "neverAsk",
                dappName: "FORCE PRIME HEROES",
                connectors: [
                    new InjectedConnector({
                        options: {id: "argentX"}
                    }),
                    new InjectedConnector({
                        options: {id: "braavos"}
                    })
                ]
            }
        )

		
        if (wallet && wallet.isConnected) {
            setConnection(wallet)
            setAccount(wallet.account)
            setAddress(wallet.selectedAddress)

            console.log("Connected to Starknet")
            console.log(String(process.env.REACT_APP_STARKNET_RPC_URL))

            const myWalletAccount = new WalletAccount({nodeUrl: String(process.env.REACT_APP_STARKNET_RPC_URL)}, wallet);

            const tokenContract = new Contract(contract_abi, contractAddress, myWalletAccount)

            await tokenContract.get_token_price(
                id,
                1,
            )
                .then(async (res) => {
                    const price = res
                    console.log("seel",price)

                    await myWalletAccount.execute([
                        {
                            contractAddress: "0x05235e5488f16e8bce172c281f2b12da33bf523b9ea359e2a93438f3b432372e",
                            entrypoint: 'set_approval_for_all',
                            calldata: CallData.compile({
                                operator: contractAddress,
                                approved: 1,
                            }),
                        },
                        {
                            contractAddress: contractAddress,
                            entrypoint: 'sell_token',
                            calldata: CallData.compile({
                                id: id,
                                token: token,
                                amount: 1
                            }),
                        },
                    ]).then(async (multiCall) => {
                        await provider.waitForTransaction(multiCall.transaction_hash)
                            .catch(e => {
                                console.log(e)
                            });
                    }).catch(e => {
                        console.log(e)
                    });

                })
                .catch(e => {
                    console.log(e)
                    alert("Error getting price from chain. Please try again later.");
                });


        }
    }

    return (
        <button className="fp-btn-bg-small text-black d-none d-sm-block" onClick={tokenBuyBtn}>Sell</button>
    )
}

export default MapSeasonTokenHeroSellBackButton