import React, {Component} from "react";
import {AccountInfoManager} from "./accountInfoManager";
import {delay} from "./common/utility/delay";
import CountdownLabel from "./common/countdownLabel";
import IconTitleValue from "./common/iconTitleValue";
import {Link} from "react-router-dom";
import {apiGetMapScores} from "./api/requests";
import MapAchievementsElement from "./MapAchievementsElement";

class MapListElement extends Component{
    constructor(props) {
        super(props);

        const token = localStorage.getItem('authToken');

        this.state = {
            dateStart: (new Date(props.config.date_start)),
            dateEnd: new Date(props.config.date_end),
            canPlay: (new Date(props.config.date_start)) < (new Date()),
            showEnd: (new Date(props.config.date_start)) < (new Date()) && (new Date(props.config.date_end)) > (new Date()) && this.props.mapType === "season",
            showStart: (new Date(props.config.date_start)) > (new Date()) && this.props.mapType === "season",
            showEnded: (new Date(props.config.date_end)) < (new Date()) && this.props.mapType === "season",
            authToken: token,
            score: null,
            grandSeasonPoints: 0,
            mapType: this.props.mapType,
            achievements: null,
        }

        this.loadUserScore = this.loadUserScore.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);

    }

    componentDidMount() {
        if(this.state.canPlay)
            this.loadUserScore()
    }

    async loadUserScore() {
        if (this.state.authToken === null) {
            return
        }
        await apiGetMapScores(
            this.state.authToken, this.props.config.grand_season, this.props.config.id
        ).then(async (response) => {
            if (response.score !== undefined) {

                const getGrandSeasonPoints = (array,score) => {
                    let res = 0
                    for (let i = 0; i < array.length; i++) {
                        if(array[i].map_score === undefined)
                            continue
                        if(array[i].grand_season_bonus === undefined)
                            continue
                        if (score > array[i].map_score)
                            res += array[i].grand_season_bonus
                    }
                    return res
                };


                this.setState({
                    score: response.score.score,
                    grandSeasonPoints: getGrandSeasonPoints(this.props.config.grand_season_scores,  response.score.score)
                        + response.score.achievements.achievement_1 * this.props.config.achievement_1
                        + response.score.achievements.achievement_2 * this.props.config.achievement_2
                        + response.score.achievements.achievement_3 * this.props.config.achievement_3,
                    achievements: response.score.achievements,
                })
            }
        }).catch(e => {
            window.alerts.alert("internal error")
        })
    }

    async clickHideTutorial(hide) {

        const alert = window.alerts?.alert;
        const success = window.alerts?.success;
        const loading = window.alerts?.loading;
        const loadingStop = window.alerts?.loadingStop;
        const id = loading("Please wait...");

        const res = await AccountInfoManager.setHideTutorial(hide);
        if (res) {
            loadingStop(id, "Done");
            await delay(500)
            window.location.reload();
        }
        else {
            alert("Internal Server Error. Please refresh the page and try again.");
            await delay(500)
            window.location.reload();
        }

        return res;
    }

    render() {

        if (this.props.mapType === "campaign" && this.props.config.campaign !== 1) {
            return <></>
        }
        if (this.props.mapType === "season" && this.props.config.campaign === 1) {
            return <></>
        }

        return (
            <>
                <div className={"container-main ms-2 me-2"}>
                    <div className="mt-5 d-flex flex-md-row flex-column">
                        <div className="">
                            <div className={"card game-view-card"}>

                                <div className={"card-body"}>
                                    { this.props.config.tutorial === 0 ? <div className="d-flex ">
                                        {this.state.showEnd ?
                                            <IconTitleValue
                                                icon="seasonEnd.svg"
                                                title="Tournament ends in">
                                                <CountdownLabel targetDate={this.state.dateEnd}/>
                                            </IconTitleValue> : <></>}
                                        {this.state.showStart ?
                                                <IconTitleValue
                                                icon="seasonEnd.svg"
                                                title="Tournament starts in">
                                                <CountdownLabel targetDate={this.state.dateStart}/>
                                            </IconTitleValue> : <></>}
                                        {this.state.showEnded ?
                                            <IconTitleValue
                                                icon="seasonEnd.svg"
                                                title="Finished">
                                            </IconTitleValue> : <></>}
                                        { this.props.config.hero_shards === 0 &&
                                        this.props.mapType === "season" &&
                                        this.state.canPlay &&
                                        (this.props.config.no_big_score === undefined || this.props.config.no_big_score === 0)
                                            ? <Link to={"/map/top?map=" + this.props.config.id} className="fp-btn-bg lite mb-auto iconTitleTile ms-auto align-content-end">Top >></Link>
                                            : <></>}
                                    </div> : <></>}

                                    { this.props.config.tutorial === 0 && this.props.config.hero_shards === 1 && Date.now() > Date.parse(this.props.config.hero_shards_start_sale) ? <div className="mt-3 d-flex flex-row justify-content-between ">
                                        {this.props.mapType === "season" && this.props.config.hero_shards === 1 && Date.now() > Date.parse(this.props.config.hero_shards_start_sale) && (this.props.config.no_big_score === undefined || this.props.config.no_big_score === 0) ? <Link to={"/map/heroes?map=" + this.props.config.id} className="fp-btn-bg lite iconTitleTile align-content-end">Mint Cards</Link> : <></>}
                                        {this.props.mapType === "season" && this.state.canPlay && (this.props.config.no_big_score === undefined || this.props.config.no_big_score === 0) ? <Link to={"/map/top?map=" + this.props.config.id} className="fp-btn-bg lite iconTitleTile align-content-end">Tournament Page</Link> : <></>}
                                    </div> : <></>}

                                    <div className={"m-3"}>
                                        {this.state.canPlay ? <Link to={"/play?map=" + this.props.config.id}>
                                            <img className={"img-fluid game-image rounded-3"}
                                                     src={this.props.config.image} alt="Force Prime Heroes"/>
                                            </Link> :
                                            <img className={"img-fluid game-image rounded-3"}
                                                 src={this.props.config.image} alt="Force Prime Heroes"/>
                                        }
                                    </div>
                                    {this.state.canPlay ? <Link to={"/play?map=" + this.props.config.id}
                                                                className="fp-btn-bg ">Play</Link> : <></>}

                                </div>
                            </div>
                        </div>
                        <div className="ms-2 block-panel-main game-info-right-part">
                            <div className="card-text white">
                                {this.state.canPlay && this.props.config.tutorial === 0 && this.state.score !== null && this.state.score !== undefined ?
                                    <div className={"d-flex pb-3"}>
                                        <IconTitleValue
                                            icon="top.svg"
                                            title="Your Highest Score">
                                            {this.state.score}
                                        </IconTitleValue>
                                        {this.props.config.no_big_score !== undefined && this.props.config.no_big_score !== 1 ?
                                            <IconTitleValue
                                                icon="prizePool.svg"
                                                title="Season Points Earned">
                                                {this.state.grandSeasonPoints}
                                            </IconTitleValue> : <></>}
                                    </div>

                                    : <></>}
                                {this.state.authToken !== null && this.props.config.tutorial === 1 ?
                                    <button className={"m-1 default-btn ms-auto"}
                                            onClick={() => this.clickHideTutorial(1)}>
                                        Hide tutorial
                                    </button> :
                                    <></>
                                }
                                <h2 className="notransform-h2 mb-4">
                                    {this.props.config.name}
                                </h2>


                                <p className="mb-4">
                                    {this.props.config.description.split('\n').map(function (item, key) {
                                        return (
                                            <span key={key}>
                                                {item}<br/>
                                                </span>
                                        )
                                    })}
                                </p>
                                <p>
                                    {this.state.canPlay ? <>
                                        { this.props.config.achievement_1 > 0 ? <h5 className={"notransform-h5"}>Map Achievements</h5> : <></>}
                                        { this.props.config.achievement_0 > 0 ?
                                            <>
                                                <MapAchievementsElement
                                                    achievement={this.props.config.achievement_0}
                                                    achievement_text={this.props.config.achievement_0_text}
                                                    authToken={this.state.authToken}
                                                    isDone={ this.state.achievements !== null ? this.state.achievements.achievement_3 : false}
                                                />
                                                <MapAchievementsElement
                                                    achievement={this.props.config.achievement_1}
                                                    achievement_text={this.props.config.achievement_1_text}
                                                    authToken={this.state.authToken}
                                                    isDone={ this.state.achievements !== null ? this.state.achievements.achievement_1 : false}
                                                />
                                                <MapAchievementsElement
                                                    achievement={this.props.config.achievement_2}
                                                    achievement_text={this.props.config.achievement_2_text}
                                                    authToken={this.state.authToken}
                                                    isDone={ this.state.achievements !== null ? this.state.achievements.achievement_2 : false}
                                                />
                                            </> :
                                            <>
                                                <MapAchievementsElement
                                                    achievement={this.props.config.achievement_1}
                                                    achievement_text={this.props.config.achievement_1_text}
                                                    authToken={this.state.authToken}
                                                    isDone={ this.state.achievements !== null ? this.state.achievements.achievement_1 : false}
                                                />
                                                <MapAchievementsElement
                                                    achievement={this.props.config.achievement_2}
                                                    achievement_text={this.props.config.achievement_2_text}
                                                    authToken={this.state.authToken}
                                                    isDone={ this.state.achievements !== null ? this.state.achievements.achievement_2 : false}
                                                />
                                                <MapAchievementsElement
                                                    achievement={this.props.config.achievement_3}
                                                    achievement_text={this.props.config.achievement_3_text}
                                                    authToken={this.state.authToken}
                                                    isDone={ this.state.achievements !== null ? this.state.achievements.achievement_3 : false}
                                                />
                                            </>}

                                    </>: <></>}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

}

export default MapListElement;
