import React from 'react'
import { useEffect, useState } from 'react'
import { connect, disconnect } from "starknetkit"
import {InjectedConnector} from "starknetkit/injected";

function Connect({onSetWallet}) {
    const [connection, setConnection] = useState('');
    const [account, setAccount] = useState('');
    const [address, setAddress] = useState('');

    useEffect(() => {
        const connectToStarknet = async() => {
            const { wallet } = await connect(
                {
                    modalMode: "neverAsk",
                    dappName: "FORCE PRIME HEROES",
                    connectors: [
                        new InjectedConnector({
                            options: {id: "argentX"}
                        }),
                        new InjectedConnector({
                            options: {id: "braavos"}
                        })
                    ]
                }
            )

            if (wallet && wallet.isConnected) {
                setConnection(wallet)
                setAccount(wallet.account)
                setAddress(wallet.selectedAddress)
                onSetWallet(wallet.selectedAddress)
            }
            connectToStarknet()
        }
    }, [])

    const connectWallet = async() => {
        const { wallet } = await connect(
            {
                dappName: "FORCE PRIME HEROES",
                connectors: [
                    new InjectedConnector({
                        options: {id: "argentX"}
                    }),
                    new InjectedConnector({
                        options: {id: "braavos"}
                    })
                ]
            }
        )
        if(wallet && wallet.isConnected) {
            setConnection(wallet)
            setAccount(wallet.account)
            setAddress(wallet.selectedAddress)
            onSetWallet(wallet.selectedAddress)
        }
    }

    const disconnectWallet = async() => {
        await disconnect()
        setConnection(undefined)
        setAccount(undefined)
        setAddress('')
    }

    return (
        <>
            {
                !connection ?
                    <button className={"fp-btn-bg w-100"} onClick={connectWallet}>Connect Wallet</button>
                    :
                    <button className={"fp-btn-bg w-100"} onDoubleClick={disconnectWallet}>{address.slice(0, 5)}...{address.slice(60, 66)}</button>
            }
        </>
    )
}

export default Connect