import React, { useState } from 'react';

import { Tooltip } from '@mui/material';

const EditableLabel = ({ valueFunc, onSave }) => {
 
  const [isEditing, setIsEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(undefined);

  const value = valueFunc();

  if (!isEditing && currentValue != value) {
     setCurrentValue(value);
  }
  
  const inputRef = React.useRef();

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    const res = await onSave(currentValue);
    if (res) {
        setIsEditing(false);
    }
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setCurrentValue(value);
  };

  const handleInputChange = (event) => {
    setCurrentValue(event.target.value);
  };

  return (
    <div className="border-bottom editable-label panel-row width100">
          {isEditing ? (
              <>
              <Tooltip open={inputRef.current?.validationMessage ? true : false} title={inputRef.current?.validationMessage}>
                    <input autoFocus type="text" value={currentValue} onChange={handleInputChange}
                        maxLength="30"
                        minLength="5"
                        pattern="[A-Za-z0-9\-]+"
                        ref={inputRef}
                    />
              </Tooltip>

              {inputRef.current?.validity.valid && <img className="drop-shadow-icon pointer" src="/icons/check.svg" onClick={handleSaveClick}/> }
              <img className="drop-shadow-icon pointer" src="/icons/cross.svg" onClick={handleCancelClick}/>
              </>
          ) : (
              <>
              <label>{currentValue}</label>
              <Tooltip title="Change nickname">
                <img className="drop-shadow-icon pointer" src="/icons/edit.svg" onClick={handleEditClick}/>
              </Tooltip>
              </>            
          )}
    </div>
  );
};


export default EditableLabel;
