import React, {useState} from "react";
import heroes from "./heroes.json";
import {AccountInfoManager} from "./accountInfoManager";
import {delay} from "./common/utility/delay";
import {useNavigate} from "react-router-dom";

function MapHeroChange({currentHero, mapId}) {

    const alert = window.alerts?.alert;
    const success = window.alerts?.success;
    const loading = window.alerts?.loading;
    const loadingStop = window.alerts?.loadingStop;

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate();

    async function setHeroOnMap(heroId) {
        while (true) {
            const id = loading("Please wait...");

            const res = await AccountInfoManager.setMapHero(mapId, heroId);

            if (res.res) {
                loadingStop(id, "Hero changed successfully");
                await delay(500)
                navigate(0);
                break
            } else {
                if (res.error.errorId === 10908) {
                    window.alerts.loadingStopFast(id, "Saving your result. Don't reload the page. Please wait a little longer.");
                    await delay(1000)
                } else {
                    window.alerts.alert("Internal Server Error. Please refresh the page and try again.");
                    break
                }
            }
        }
    }

    const heroesOn = heroes.filter(hero => hero.playable );

    return (
        <>
            <div className="">
                <h1 className={"notransform-h2 text-center mb-3"}>Choose a hero</h1>
                <div className={"row justify-content-around"}>
                    {heroesOn.map((hero) => {
                        return <div key={hero.guid} className={"col-12 col-lg-5 rounded p-3 mb-2 m-0 m-sm-2"}>
                            <div key={hero.guid}
                                 className="mb-3 d-flex flex-row hero-element">
                                <div className={"d-flex flex-column align-content-center me-3"}>
                                    <img src={hero.imageUrl}
                                         className="hero-element-image rounded-5" alt={hero.heroName}/>
                                </div>
                                <div className="text-white choose-hero-card-body">
                                    <h5 className="main-color-important">{hero.heroName}</h5>
                                    <p className="">{hero.heroDescription.split('\n').map(function (item, key) {
                                        return (
                                            <span key={key}>
                                                {item}<br/>
                                                </span>
                                        )
                                    })}</p>
                                </div>
                                <div className={"ms-auto d-flex flex-column justify-content-start"}>
                                    <button className="fp-btn-bg-small text-black"
                                            onClick={() => setHeroOnMap(hero.id)}>Choose
                                    </button>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </>
    );
}

export default MapHeroChange;
