import { useCountdown } from "./utility/useCountdown";

function p(value) {
    return String(value).padStart(2, '0');
}

const CountdownLabel = ({ targetDate }) => {
    const [days, hours, minutes, seconds] = useCountdown(targetDate);
  
    if (days + hours + minutes + seconds <= 0) {
      return ("00:00:00")
    } else {
      const time = `${p(hours)}:${p(minutes)}:${p(seconds)}`;
      return days > 0 ? `${days}d ${time}` : time;  
    }
  };

export default CountdownLabel;