import React from "react";
import {TailSpin} from "react-loader-spinner";


export class Loading extends React.Component {
    constructor(props) {
        super(props);
    }


    render() {
        return (
            <div className={"d-flex flex-column align-items-center p-5"}>
                <div>
                    {this.props.loading_text}
                </div>
                <div>
                    <TailSpin  wrapperStyle={{display: "inline"}} wrapperClass="" color={"#DFC16C"} height={60} radius={"3"} />
                </div>
            </div>
        )
    }
}

export class LoadingInline extends React.Component {
    constructor(props) {
        super(props);
    }


    render() {
        return (
                    <TailSpin  wrapperStyle={{display: "inline"}} wrapperClass={""} color={"#DFC16C"} height={10} radius={"3"} />
        )
    }
}
