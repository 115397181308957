//window.accountInfoManager = new accountInfoManager();

import {
    apiGetGameReset, apiGetMapConfig, apiGetMapReset,
    apiGetSeasonDiscordCode,
    apiGetSelfProfileData,
    apiGetWalletInfo, apiSeasonPassClaim, apiSetHideTutorial, apiSetMapSetHero,
    apiSetUsername
} from "./api/requests";
import { Signal } from "./common/utility/signal";

export const AccountInfoManager = new accountInfoManager();

function accountInfoManager() {
    console.log("New: accountInfoManager");

    let userData = undefined;
    let userDataPromise = undefined;

    let userSeasonDiscordCode = undefined;


    const userUpdatesSignal = new Signal();

    this.userUpdatesSignal = userUpdatesSignal;

    this.retrieveUserDataPromise = () => {
        if (userDataPromise)
            return userDataPromise;

        if (userData)
            return new Promise((resolve) => resolve(userData));

        refreshUserData();
        return userDataPromise;
    }

    function onUpdate(updateData) {
        if (updateData.type == "stacks_income_transfer" ||
            updateData.type == "polygon_income_transfer" ||
            updateData.type == "in_app_purchase")
        {
            refreshUserData();
        }
    }

    function refreshUserData() {
        userDataPromise = getUserDataPromise();
    }


    function getUserDataPromise() {
        return new Promise((resolve, reject) => {
            async function getUserData() {
                try {
                    const token = localStorage.getItem('authToken');
                    userData = await apiGetSelfProfileData(token);
                    console.log("userData",userData)
                    if(userData.user === null) {
                        reject("User data is null");
                        throw new Error("User data is null");
                    }

                    userUpdatesSignal.fire();

                    resolve(userData);
                } catch (err) {
                    resolve(null);
                } finally {

                }
            }
            async function getDiscordCode() {
                try {
                    const token = localStorage.getItem('authToken');
                    userSeasonDiscordCode = await apiGetSeasonDiscordCode(token);
                    userUpdatesSignal.fire();

                    resolve(userSeasonDiscordCode);
                } catch (err) {
                    resolve(null);
                } finally {

                }
            }
            getUserData();
            getDiscordCode();
        });
    }

    this.refresh = () => {
        refreshUserData();
    }

    this.getUsername = () => {
        return userData?.user.username ?? "";
    }

    this.fpBalance = () => {
        return userData?.balance ?? 0;
    }

    this.lotteryTickets = () => {
        return userData?.lottery ?? 0;
    }

    this.getUsername = () => {
        return userData?.user.username ?? "";
    }

    this.getUserSeasonDiscordCode = () => {
        return userSeasonDiscordCode?.data ?? "";
    }

    this.setNewNick = async(nickname) => {
        try {
            const token = localStorage.getItem('authToken');
            console.log(token)
            const res = await apiSetUsername(token, nickname);
            if (!res.error) {
                userData.user.username = nickname;
                return true;
            }
            return false;
        } catch (err) {

        }
        return false;
    }

    this.setHideTutorial = async(hide) => {
        try {
            const token = localStorage.getItem('authToken');
            console.log(token)
            const res = await apiSetHideTutorial(token, hide);
            if (!res.error) {
                userData.user.hide_tutorial = hide;
                return true;
            }
            return false;
        } catch (err) {

        }
        return false;
    }

    this.setMapHero = async(mapId, heroId) => {
        try {
            const token = localStorage.getItem('authToken');
            console.log(token)
            const res = await apiSetMapSetHero(token, mapId, heroId);
            return {res: !res.error, error: res.errorId};
        } catch (err) {
            return {res: false, error: err};
        }
    }

    this.gameReset = async() => {
        try {
            const token = localStorage.getItem('authToken');
            const res = await apiGetGameReset(token);
            return !res.error;

        } catch (err) {

        }
        return false;
    }

    this.mapReset = async(mapId) => {
        try {
            const token = localStorage.getItem('authToken');
            const res = await apiGetMapReset(token, mapId);
            return {res: !res.error, error: res.errorId};
        } catch (err) {
            return {res: false, error: err.errorId};
        }
    }

    this.mapConfig = async(mapId) => {
        try {
            const token = localStorage.getItem('authToken');
            const res = await apiGetMapConfig(token, mapId);
            return {res: !res.error, error: res.errorId};
        } catch (err) {
            return {res: false, error: err.errorId};
        }
    }

    this.claimSeasonPass = async(seasonId, claimId) => {
        try {
            const token = localStorage.getItem('authToken');
            console.log(token)
            return await apiSeasonPassClaim(token, seasonId, claimId);
        } catch (err) {
            return {res: false, error: err.errorId};
        }
    }

}
