const getHeroRewardPoolDeduction = (mapStatic, place) => {
    let configData = {
        1: 60,
        2: 30,
        3: 10,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
    }
    if(mapStatic.id === "season_GS5_small_6") {
        configData[3] = 0
        configData[8] = 10
    }
    return configData[place] / 100
};

export default getHeroRewardPoolDeduction;