import React from "react";
import { configuration } from "./config";

import { iconPath } from "./common/paths";

export function Footer() 
{
    return (
        <div className="footer ms-2 me-2 pt-5 pb-5 ">
            <a href={configuration.twitter} target={"_blank"} className={"m-1"}>{getContent("twitter")}</a>
            <a href={configuration.discord} target={"_blank"} className={"m-1"}>{getContent("discord")}</a>
            <a href="https://fp-heroes.gitbook.io/how-to-play/" target={"_blank"}
               className={"m-1"}>{getContent("how to play")}</a>
        </div>
    );
}

function getContent(text) {
    const upImg = <img src={iconPath("arrow-up.svg")}/>;

    return <span>{text} {upImg}</span>
}

function mainColoredDot() {
    return <span className="main-color">.</span>
}
