import Modal from 'react-bootstrap/Modal';
import {useState} from "react";

function AdminModalAuthPin({label, onPinEnter}) {
    const alert = window.alerts?.alert;
    const success = window.alerts?.success;
    const loading = window.alerts?.loading;
    const loadingStop = window.alerts?.loadingStop;

    const [show, setShow] = useState(true);
    const handleClose = () => {setShow(false)}
    const handleShow = () => setShow(true);
    const [currentValue, setCurrentValue] = useState(undefined);

    const handleInputChange = (event) => {
        setCurrentValue(event.target.value);
    };

    function onPinSubmit() {
        setShow(false);
        onPinEnter(currentValue)
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} dialogClassName="fp-modal-form">
                <Modal.Body>
                    <div className="d-flex flex-column user-box">
                        <h1 className={"notransform-h1 text-center"}>Enter pin</h1>
                            <div className={"p-5 d-flex flex-column align-items-center"}>
                                <input type="password" className="form-control" placeholder="pin" onChange={handleInputChange} />
                                <button className={"m-1 fp-btn-bg w-100"} onClick={onPinSubmit}>Submit</button>
                            </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AdminModalAuthPin;
