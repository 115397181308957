export const configuration = {
    name: "Force Prime",
    appIcon: "https://playfp.xyz/android-chrome-192x192.png",
    twitter: "https://twitter.com/ForcePrime_io",
    discord: "https://discord.gg/8SFzxSgbq3",
    whitepaper: "https://force-prime.gitbook.io/forceprime/",
    contactUrl: "https://forms.gle/MBxELR3P6fEkgZmm8",
    sentryUrl: "https://61168b8b43a345fb80160ec78f48af5e@o4504899046080512.ingest.sentry.io/4504899070918656",
    googleTagId: "G-NKYPVYZRYS",
    supportedTokens :  ["stx"],
    depositChains: process.env.REACT_APP_DEPOSIT_CHAINS?.split(' ') || ["stacks"],
    lootboxes: [
        {id: "gold", name: "Golden chest", img: require("./static/lootbox-1.png"),
            hint: "Golden chest. Contains valuable resources, including a significant chance to get Force Prime NFT. The date of the chests opening will be announced later."},
        {id: "silver", name: "Silver chest", img: require("./static/lootbox-2.png"),
            hint: "Silver chest. Contains valuable resources, including a chance to get Force Prime NFT. The date of the chests opening will be announced later."},
        {id: "bronze", name: "Bronze chest", img: require("./static/lootbox-3.png"),
            hint: "Bronze chest. Contains valuable resources, including a small chance to get Force Prime NFT. The date of the chests opening will be announced later."},
        {id: "wooden", name: "Wooden chest", img: require("./static/lootbox-4.png"),
            hint: "Wooden chest. Contains valuable resources. The date of the chests opening will be announced later."},
    ]
}


