// Wrapper
import React from "react";
import {useLocation, useParams, useSearchParams} from "react-router-dom";

const ElementWrapper = (props) => {
    const params = useParams();
    const locations = useLocation();
    const [searchParams] = useSearchParams();
    const Element = props.routeElement;

    return <Element params={params} locations={locations} search={searchParams} {...props} />;
};

export default ElementWrapper;