export function Signal() {
    const listeners = [];

    this.subscribe = subscribe;
    this.unsubscribe = unsubscribe;
    this.fire = fire;

    function subscribe(listener) {
        // console.log("signal subscribe");
        if (!listeners.includes(listener))
            listeners.push(listener);
    }

    function unsubscribe(listener) {
        const idx = listeners.indexOf(listener);
        // console.log("signal unsubscribe", idx);
        if (idx >= 0)
            listeners.splice(idx, 1);
    }

    function fire() {
        listeners.forEach(callback => callback());
    }
}