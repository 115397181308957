import React, {Component} from "react";

import 'react-toastify/dist/ReactToastify.css';
import {
    apiGetHeroesTokenData,
    apiGetMapSeasonInfo, apiGetMapStaticSeasonConfig, apiGetTokenHeroData, apiGetTokenHeroDataIds,
} from "./api/requests";
import {Loading, LoadingInline} from "./common/utility/loading";
import CountdownLabel from "./common/countdownLabel";
import IconTitleValue from "./common/iconTitleValue";
import heroes from "./heroes.json";
import MapSeasonTokenHeroElement from "./MapSeasonTokenHeroElement";
import MapSeasonTokenHeroRewardButton from "./MapSeasonTokenHeroRewardButton";
import {Link} from "react-router-dom";
import MapSeasonTokenHeroMintButton from "./MapSeasonTokenHeroMintButton";
import MapSeasonTokenHeroSellBackButton from "./MapSeasonTokenHeroSellBackButton";
import MapSeasonTokenHeroMintButtonAtPanel from "./MapSeasonTokenHeroMintButtonAtPanel";
import MapSeasonTokenHeroRewardButtonAtPanel from "./MapSeasonTokenHeroRewardButtonAtPanel";
import getHeroRewardPoolDeduction from "./heroes/heroRewardPoolDeduction";

class MapSeasonTokenHeroesPanel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingSeasonTop: true,
            loadingFromServer: true,
            loading: true,
            noSeason: false,
            isHero: 1,
            heroesOn: heroes.filter(hero => hero.playable ),
            mapId : this.props.mapId,
            prizePool: 0,
            heroesIds: [],
            noScores: false,
        }
        this.loadSeasonTop = this.loadSeasonTop.bind(this);
        this.loadingMapStaticData = this.loadingMapStaticData.bind(this);
        this.loadCardsDataFromServer = this.loadCardsDataFromServer.bind(this);
        this.getHeroesPrize = this.getHeroesPrize.bind(this);

    }

    async componentDidMount() {
        await this.loadingMapStaticData();
        await this.loadSeasonTop()
    }

    async loadCardsDataFromServer(heroesIds) {
        await apiGetTokenHeroDataIds(
            heroesIds,
            this.state.mapId,
        ).then(async (response) => {
            if (response.heroes !== undefined && response.heroes !== null) {
                this.setState({
                    heroesData: response.heroes,
                    loadingFromServer: false,
                })
            }
        }).catch(e => {
            this.setState({
                heroesData: [],
            })
            this.setState({
                loadingFromServer: true,
            })
        })
    }

    async loadingMapStaticData() {
        await apiGetMapStaticSeasonConfig(
            this.state.mapId
        ).then(async (response) => {

            const getPlaces = (array) => {
                let res = {}
                for (let i = 0; i < array.length; i++) {
                    res[array[i].place] = array[i].score
                }
                return res
            };

            let placeConfig = response.data.config.place_scores;
            let placeConfigHeroes = response.data.config.hero_place_scores;

            this.setState({
                config: response.data.config,
                placesScores: getPlaces(placeConfig),
                placesScoresHeroes: getPlaces(placeConfigHeroes),
            })

            this.setState({
                loading: false,
            })
        })
    }

    async loadSeasonTop() {
        await apiGetMapSeasonInfo(
            this.state.mapId,
            true,
        ).then(async (response) => {
            //await delay(1000);
            const shardStartSaleDate = Date.parse(response.map_static.config.hero_shards_start_sale)
            const seasonEndDate = Date.parse(response.map_static.config.date_end)
            const seasonStartDate = Date.parse(response.map_static.config.date_start)
            if  (Date.now() > shardStartSaleDate &&  Date.now() < seasonStartDate) {
                let players = await apiGetMapSeasonInfo(
                    this.state.mapId,
                    true,
                    1
                ).then(async (response) => {
                    this.setState({
                        noScores: true,
                    })
                    return response.leaderboard.users
                }).catch( e => {
                    console.log(e);
                    window.alerts.alert("internal error")
                })
                const ids = players.map(x => {return x.uid});

                let infos

                const heroesOn = heroes.filter(hero => hero.playable );
                infos = heroesOn.map((x, idx) => { return {uid: x.id, username: x.heroName, avatar: x.imageUrl} });
                infos = {users: infos}

                players = players.map((x, idx) => { return {...x, ...infos.users.find(y => y.uid == x.uid) }});

                let playersUid = players.map((x, idx) => { return parseInt(x.uid)});
                this.setState({
                    heroesIds: playersUid,
                    loadingSeasonTop: false,
                    playersHeroes: players,
                    mapStatic: response.map_static,
                })
                await this.loadCardsDataFromServer(playersUid)
            } else {
                let players = response.leaderboard.users;
                const ids = players.map(x => {return x.uid});

                let infos

                const heroesOn = heroes.filter(hero => hero.playable );
                infos = heroesOn.map((x, idx) => { return {uid: x.id, username: x.heroName, avatar: x.imageUrl} });
                infos = {users: infos}

                players = players.map((x, idx) => { return {...x, ...infos.users.find(y => y.uid == x.uid) }});

                let playersUid = players.map((x, idx) => { return parseInt(x.uid)});
                this.setState({
                    heroesIds: playersUid,
                    loadingSeasonTop: false,
                    playersHeroes: players,
                    mapStatic: response.map_static,
                })
                await this.loadCardsDataFromServer(playersUid)
            }

        }).catch(e => {
            console.log(e);
            window.alerts.alert("internal error")
        }).finally( async () => {

        })

    }

    getHeroesPrize() {
        if(this.state.loadingFromServer === true) {
            return <LoadingInline />
        }
        return parseFloat(this.state.prizePool).toFixed(2)
    }


    render() {
        if(this.state.noSeason === true) {
            return <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                <div className="panel-row width100" style={{justifyContent: "space-between"}}>
                    <h3 className={"notransform-h3"}>We are currently doing an update, please try refreshing in 5 minutes!</h3>
                </div>
            </div>
        }
        if(this.state.loading === true || this.state.loadingFromServer === true || this.state.loadingSeasonTop === true) {
            return <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                <div className="panel-row width100" style={{justifyContent: "space-between"}}>
                    <h2 className={"notransform-h1"}>Loading data...</h2>
                </div>
                <Loading></Loading>

            </div>

        }


        if(this.state.mapStatic === undefined || this.state.mapStatic === null) {
            return <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                <div className="panel-row width100" style={{justifyContent: "space-between"}}>
                    <h1 className={"notransform-h1"}>Current Season Map</h1>
                </div>
                <div>
                    <p>Map not found</p>
                </div>
            </div>
        }

        let pageHeader = this.state.mapStatic.config.name;

        const shardStartSaleDate = Date.parse(this.state.mapStatic.config.hero_shards_start_sale)
        const seasonEndDate = Date.parse(this.state.mapStatic.config.date_end)
        const seasonStartDate = Date.parse(this.state.mapStatic.config.date_start)

        let totalSupply = 0
        let totalPrizePool = 0

        for (let i = 0; i < this.state.heroesData.length; i++) {
            totalSupply += this.state.heroesData[i] ? this.state.heroesData[i].total_supply : 0
            totalPrizePool = this.state.heroesData[i] ? this.state.heroesData[i].prize_pool : 0
        }

        let tournamentData = <>
            <div className="pt-3 pb-3">
                <div className="d-flex ">
                    <IconTitleValue
                        icon="prizePool.svg"
                        title="Heroes prize">
                        {parseFloat(totalPrizePool).toFixed(2)} STRK
                    </IconTitleValue>
                    <IconTitleValue
                        icon="seasonEnd.svg"
                        title="Tournament ends in">
                        <CountdownLabel targetDate={new Date(this.state.config.date_end)}/>
                    </IconTitleValue>
                    <Link to={"/map/heroes?map=" + this.state.mapId}
                          className="fp-btn-bg lite iconTitleTile align-content-end">Advanced Mint</Link>
                </div>
            </div>
        </>

        if (Date.now() < seasonStartDate) {
            tournamentData = <>
                <div className="pt-3 pb-3">
                    <div className="d-flex ">
                        <IconTitleValue
                            icon="prizePool.svg"
                            title="Heroes prize">
                            {parseFloat(totalPrizePool).toFixed(2)} STRK
                        </IconTitleValue>
                        <IconTitleValue
                            icon="seasonEnd.svg"
                            title="Tournament starts in">
                            <CountdownLabel targetDate={new Date(this.state.config.date_start)}/>
                        </IconTitleValue>
                        <Link to={"/map/heroes?map=" + this.state.mapId}
                              className="fp-btn-bg lite iconTitleTile align-content-end">Advanced Mint</Link>
                    </div>
                </div>
            </>
        }

        if (Date.now() > seasonEndDate) {
            tournamentData = <>
                <div className="pt-3 pb-3">
                    <div className="d-flex ">
                        <IconTitleValue
                            icon="prizePool.svg"
                            title="Heroes prize">
                            {parseFloat(totalPrizePool).toFixed(2)} STRK
                        </IconTitleValue>
                        <Link to={"/map/heroes?map=" + this.state.mapId}
                              className="fp-btn-bg lite iconTitleTile align-content-end">Advanced Mint</Link>
                    </div>
                </div>
            </>;
        }

        let rewardBtn = <></>

        if(Date.now() > seasonEndDate) {
            rewardBtn = <>
                <span className={"mb-3"}>
                    <MapSeasonTokenHeroRewardButtonAtPanel
                        contractAddress={this.state.config.chain_tournament_contract}
                        id={this.state.config.chain_tournament_id}
                    />
                </span>
            </>
        }

        return (
            <>
                <div className={"pt-4 d-flex flex-column align-content-center table-responsive text-light"}>
                    <h2 className={"notransform-h2 pt-3"}>Heroes</h2>
                    {tournamentData}
                    {rewardBtn}
                    <table role="table" className="leaderboard-table" style={{minWidth: "0px"}}>
                        <thead>
                        <tr role="row" className={"table-row"}>
                            <th colSpan="1" role="columnheader"
                                className={"table-cell"}>
                                <div className="default-tile-header iconTitleTile-title">Place</div>
                            </th>
                            <th colSpan="1" role="columnheader"
                                className={"table-cell"}>
                                <div className="default-tile-header iconTitleTile-title">Hero</div>
                            </th>
                            <th colSpan="1" role="columnheader"
                                className={"table-cell"}>
                                <div className="default-tile-header iconTitleTile-title">Score</div>
                            </th>
                            <th colSpan="1" role="columnheader"
                                className={"table-cell"}>
                                <div className="default-tile-header iconTitleTile-title">Season points</div>
                            </th>
                            <th colSpan="1" role="columnheader"
                                className={"table-cell"}>
                                <div className="default-tile-header iconTitleTile-title">Prize per card</div>
                            </th>
                            <th colSpan="1" role="columnheader"
                                className={"table-cell"}>
                                <div className="default-tile-header iconTitleTile-title">Mint price</div>
                            </th>
                            <th colSpan="1" role="columnheader"
                                className={"table-cell"}>
                                <div className="default-tile-header iconTitleTile-title">&nbsp;</div>
                            </th>
                        </tr>
                        </thead>
                        <tbody role="rowgroup">
                        {this.state.playersHeroes.map((v, k) => {

                            const getHeroData = (uid) => {
                                return this.state.heroesData.find(x => x.hero_id === uid)
                            }

                            const heroData = getHeroData( v.uid );

                            let mintBtn = <></>

                            if(shardStartSaleDate < Date.now() && seasonEndDate > Date.now()) {
                                mintBtn = <>
                                    <span className={"me-1"}>
                                        <MapSeasonTokenHeroMintButtonAtPanel
                                            contractAddress={heroData.hero.tournament_contract}
                                            id={this.state.config.chain_tournament_id}
                                            token={heroData.hero_id}
                                        />
                                    </span>
                                </>
                            }

                            return <tr key={v.uid} role="row" className={"table-row"}>
                                <td role="cell"
                                    className={"table-cell"}>
                                    <div className="leaderboard-table-place">
                                        <div><span>{v.place}</span></div>
                                    </div>
                                </td>
                                <td role="cell"
                                    className={"table-cell"}>
                                    <img src={v.avatar}
                                         className="hero-element-image-table rounded-1 me-1" alt={v.username}/>
                                        {v.username}
                                </td>
                                <td role="cell"
                                    className={"table-cell"}
                                    style={{alignSelf: "start"}}> { this.state.noScores ? 0 : v.points}
                                </td>
                                <td role="cell"
                                    className={"table-cell"}
                                    style={{alignSelf: "start"}}> {this.state.placesScoresHeroes[v.place] !== undefined ? this.state.placesScoresHeroes[v.place] : ""}
                                </td>
                                <td role="cell"
                                    className={"table-cell"}
                                    style={{alignSelf: "start"}}> {heroData.total_supply > 0 ? parseFloat( heroData.prize_pool * getHeroRewardPoolDeduction(this.state.config, v.place) / heroData.total_supply).toFixed(2) : "n/a"}
                                </td>
                                <td role="cell"
                                    className={"table-cell"}
                                    style={{alignSelf: "start"}}> {heroData.current_price}
                                </td>
                                <td role="cell"
                                    className={"table-cell"}
                                    style={{alignSelf: "start"}}>
                                    {mintBtn}
                                </td>
                            </tr>
                        })}


                        </tbody>
                    </table>

                </div>
            </>
        )
    }
}

export default MapSeasonTokenHeroesPanel;
