import React from "react";
import ModalAuth from "./ModalAuth";
import ModalProfile from "./ModalProfile";
import ModalHowToPlay from "./ModalHowToPlay";
import {Link} from "react-router-dom";


class UpperPanel extends React.Component {
    constructor(props) {
        super(props);

        const token = localStorage.getItem('authToken');
        this.state = {
            authToken: token,
        }
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {

        let loginDiv = <></>

        if(( this.props.account !== null && this.props.account !== undefined && this.props.account !== "")) {
            loginDiv =
                <div className={"ms-auto login-element d-flex justify-content-end flex-wrap align-items-center"}>
                    <Link to="/" className="fp-btn-bg">Maps</Link>
                    <ModalHowToPlay label={"How To Play"} classNameType={"fp-btn-bg"}/>
                    <ModalProfile label={"Profile"}/>
                    <a className={"fp-btn-bg"} type="button" onClick={this.props.disconnectWallet}>
                        Disconnect
                    </a>
                </div>
        } else {
            loginDiv =
                <div className={"ms-auto login-element d-flex justify-content-end flex-wrap align-items-center"}>
                    <ModalAuth label={"Connect"} onSetWallet={this.props.onSetWallet} onClickWallet={this.props.onClickWallet} onClickAnonymous={this.props.onClickAnonymous}/>
                </div>
        }

        return (
            <div className="account-panel d-flex flex-md-row flex-column pt-2 pt-sm-2 pt-md-3 pt-xxl-2 pt-xl-2 pt-md-2 ps-2 pe-2 pb-3 pb-sm-0">
                <div className="logo-container d-flex flex-column justify-content-start pb-md-0 pb-3">
                    <div className={""}>
                        <a href={"/"}><img alt={"merge-game"} className={"logo-image"} src={require('./static/logo-v05.png')}/></a>
                    </div>
                </div>

                {loginDiv}
            </div>
        );
    }
}

export default UpperPanel;
