import React, { Component } from "react";
import 'react-toastify/dist/ReactToastify.css';
import {
    apiGetHeroData, apiGetHeroSeasonHistory, apiGetHeroUserData, apiGetLeaderboard, apiGetUserInfoMany,
} from "./api/requests";
import { LoadingInline } from "./common/utility/loading";
import { delay } from "./common/utility/delay";
import heroes from "./heroes.json";
import LineChart from "./common/lineChart";
import { FaShieldAlt, FaCoins } from 'react-icons/fa';
import {LuSwords} from "react-icons/lu";
import {GiWalkingBoot} from "react-icons/gi";
import units from "./units.json";

class HeroPage extends Component {
    constructor(props) {
        super(props);

        const token = localStorage.getItem('authToken');

        const selfHero = heroes.filter(hero => hero.id === this.props.search.get("hero_id"))[0];

        this.state = {
            heroId: this.props.search.get("hero_id"),
            loadingHeroSeasonData: true,
            loadingFromServer: true,
            loadingFromServerUserData: true,
            authToken: token,
            cardsMinted: 0,
            heroDataJson: selfHero,
        }

        this.loadCardDataFromServer = this.loadCardDataFromServer.bind(this);
        this.loadUserCardDataFromServer = this.loadUserCardDataFromServer.bind(this);
        this.loadHeroSeasonHistoryData = this.loadHeroSeasonHistoryData.bind(this);

        this.heroSeasonHistory = this.heroSeasonHistory.bind(this);
        this.renderStatsDetails = this.renderStatsDetails.bind(this);
    }

    componentDidMount() {
        this.loadCardDataFromServer()
        this.loadHeroSeasonHistoryData()
        this.loadUserCardDataFromServer()
    }

    renderHeroArmy() {
        const { heroDataJson } = this.state;
        if (!heroDataJson || !heroDataJson.army) {
            return null;
        }

        const armyElements = Object.entries(heroDataJson.army).map(([unitId, quantity]) => {
            const unit = units.find(u => u.id === unitId);

            if (!unit || !unit.unitName || !unit.imageUrl) {
                return null;
            }

            return (
                <div key={unitId} className="army-unit">
                    <div className="army-unit-image-wrapper">
                        <img src={unit.imageUrl} alt={unit.unitName} className="army-unit-image" />
                        <div className="army-unit-quantity">х{quantity}</div>
                    </div>
                    <div className="army-unit-name">{unit.unitName}</div>
                </div>
            );
        });

        return (
            <div className="hero-army d-flex flex-row mt-1">
                {armyElements}
            </div>
        );
    }

    async loadHeroSeasonHistoryData() {
        await apiGetHeroSeasonHistory(
            this.state.heroId
        ).then(async (response) => {
            if (response.data !== undefined && response.data !== null) {
                this.setState({
                    heroSeasonData: response.data.rows,
                    loadingHeroSeasonData: false,
                })
            }
        }).catch(e => {
            this.setState({
                heroSeasonData: {},
                loadingHeroSeasonData: true,
            })
        })
    }

    async loadCardDataFromServer() {
        await apiGetHeroData(
            this.state.heroId
        ).then(async (response) => {
            if (response.hero !== undefined && response.hero !== null) {
                this.setState({
                    heroData: {
                        tokenContract: response.hero.token_contract,
                        cardPrice: response.current_price,
                        cardsMinted: response.count,
                        hero: response.hero,
                    },
                })

                await apiGetLeaderboard(
                    1, 50, true
                ).then(async (response) => {
                    await delay(1000);

                    if(response.leaderboard.users === null) {
                        this.setState({
                            players: [],
                            loadingFromServer: false,
                        })
                        return
                    }

                    let players = response.leaderboard.users;
                    const heroesOn = heroes.filter(hero => hero.playable );
                    let infos = heroesOn.map((x) => { return {uid: x.id, username: x.heroName, avatar: x.imageUrl} });
                    infos = {users: infos}

                    players = players.map((x) => {
                        return {...x, ...infos.users.find(y => y.uid == x.uid)}
                    });

                    let heroSeasonData = players.filter(x => x.uid === this.state.heroId)[0]
                    this.setState({
                        selfSeasonData: heroSeasonData,
                        players: players,
                        loadingFromServer: false,
                    })
                }).catch(e => {
                    window.alerts.alert("internal error. please try again later")
                })

            }
        }).catch(e => {
            this.setState({
                heroData: {},
                loadingFromServer: true,
            })
        })
    }

    async loadUserCardDataFromServer() {
        if(this.state.authToken === null) {
            this.setState({
                loadingFromServerUserData: true,
                userCardsMinted: 0,
            })
            return
        }
        await apiGetHeroUserData(
            this.state.authToken,
            this.state.heroId,
        ).then(async (response) => {
            if (response.token_amount !== undefined && response.token_amount !== null) {
                this.setState({
                    userCardsMinted: response.token_amount,
                    loadingFromServerUserData: false,
                })
            }
        }).catch(e => {
            this.setState({
                heroData: {
                    userCardsMinted: 0,
                },
                loadingFromServerUserData: true,
            })
        })
    }

    heroSeasonHistory() {
        if(this.state.loadingFromServer) {
            return <LoadingInline />
        }
        return <div className={"d-flex flex-row justify-content-center mt-4"}>
            <div className={"w-75"}>
                <h3 className={"notransform-h3"}>Places in recent tournaments</h3>
                <LineChart data={this.state.heroSeasonData} />
            </div>
        </div>
    }

    renderStatsDetails() {
        const { heroDataJson } = this.state;
        if (!heroDataJson || !heroDataJson.stats) {
            return null;
        }
        return (
            <div className="mt-1" >
                    <div className=" bg-transparent text-white d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center me-2">
                            <LuSwords className="me-2" /> <strong>Attack:</strong>
                        </div>
                        <span>{heroDataJson.stats.Attack}</span>
                    </div>
                    <div className=" bg-transparent text-white d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center me-2">
                            <FaShieldAlt className="me-2" /> <strong>Defense:</strong>
                        </div>
                        <span>{heroDataJson.stats.Defence}</span>
                    </div>
                    <div className=" bg-transparent text-white d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center me-2">
                            <GiWalkingBoot className="me-2" /> <strong>Speed:</strong>
                        </div>
                        <span>{heroDataJson.stats.Speed}</span>
                    </div>
                    <div className="bg-transparent text-white d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center me-2">
                            <FaCoins className="me-2" /> <strong>Wealth:</strong>
                        </div>
                        <span>{heroDataJson.stats.Income}</span>
                    </div>

            </div>
        );
    }

    render() {
        if (this.state.loadingFromServer) {
            return <LoadingInline />
        }
        return  <>
            <div className="ms-2 me-2 mb-3 d-flex flex-column align-content-center pt-4">
                <h1 className="notransform-h2 main-color-important mb-2"
                    style={{fontSize: "2.5rem"}}>{this.state.heroData.hero.hero_name}</h1>

                <div className="mb-3 d-flex flex-row hero-element">
                    <div className={"d-flex flex-column align-content-center me-4"}>
                        <img src={this.state.heroData.hero.image_url}
                             className="hero-element-image rounded-5" alt={this.state.heroData.hero.hero_name}/>
                    </div>
                    <div className="text-white choose-hero-card-body pe-3">
                        <div className={"d-flex flex-row flex-wrap"}>
                            <div className={"me-5"}>
                                <h3>Stats</h3>
                                {this.renderStatsDetails()}
                            </div>
                            <div>
                                <h3>Army</h3>
                                {this.renderHeroArmy()}
                            </div>

                        </div>
                    </div>

                    <div className="text-white choose-hero-card-body">
                        <h5 className="main-color-important">&nbsp;</h5>
                    </div>
                    <div className={"ms-auto d-flex flex-row align-items-start "}>

                    </div>
                </div>
                {this.heroSeasonHistory()}

            </div>
        </>
    }
}

export default HeroPage;
