import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const transformData = (data) => {
    let data2 = data.filter((d, index) => {
        //season_GS4_C3_1
        // season_GS4_C3_2
        // season_GS4_C3_3
        // season_GS4_C3_4
        // season_GS4_C3_5
        // season_test
        // season_GS5_test
        const badBuckets = ["season_GS4_C3_1", "season_GS4_C3_2", "season_GS4_C3_3", "season_GS4_C3_4", "season_GS4_C3_5","season_test","season_GS5_test"]
        if(badBuckets.includes(d.bucket_id) === true) {
            return
        }
        return d;
    })
    return data2.map((d, index) => {
        return {
            x: index + 1, // Индекс по оси X
            y: d.place,   // Значение по оси Y
        };
    });
};

const LineChart = ({ data, chartHeight = 400  }) => {
    const chartData = transformData(data);

    const yValues = chartData.map(point => point.y);
    const yMin = 1;//Math.min(...yValues);
    const yMax = 8;//Math.max(...yValues);

    const tickPositions = [];
    for (let y = yMin; y <= yMax; y++) {
        if (y !== 0) { // Исключаем Y=0
            tickPositions.push(y);
        }
    }

    const options = {
        chart: {
            type: 'line',
            backgroundColor: '#13181D',
            spacingTop: 40,    // Отступ сверху в пикселях
            spacingBottom: 30, // Отступ снизу в пикселях
            spacingRight: 40,
            height: chartHeight, // Задаем высоту графика
        },
        legend: {
            enabled: false, // Отключаем легенду
        },
        title: {
            text: null, // Убираем заголовок
        },
        xAxis: {
            title: {
                text: null, // Убираем название оси X
            },
            type: 'linear',
            tickInterval: 1,
            labels: {
                enabled: false,
            },
            lineColor: '#FFFFFF',
            lineWidth: 0,
            tickLength: 0,
        },
        yAxis: {
            title: {
                text: 'place',
                style: {
                    color: '#FFFFFF',
                },
            },
            reversed: true,
            tickInterval: 1,
            gridLineColor: '#2A2A2A',
            labels: {
                style: {
                    color: "#FFFFFF",
                },
                formatter: function () {
                    if(this.value !==0 && this.value !== 9)
                        return `${this.value}`; // Можно добавить префикс/суффикс, например: `Step ${this.value}`
                    else
                        return "";
                },
            },
            tickPositions: tickPositions,
            gridLineWidth: 1,
        },
        tooltip: {
            backgroundColor: '#1F1F1F',
            borderColor: '#4CAF50',
            style: {
                color: '#FFFFFF',
            },
            formatter: function () {
                return `<b>Place: ${this.y}`;
            },
        },
        plotOptions: {
            line: {
                color: '#000dff',
                marker: {
                    enabled: true,
                    fillColor: '#FFA500',
                    lineWidth: 1,
                    lineColor: '#000dff',
                },
            },
            series: {
                states: {
                    inactive: {
                        enabled: false, // Отключаем потемнение других серий при наведении
                    },
                },
            },
        },
        credits: {
            enabled: false,
        },
        series: [
            {
                name: 'Данные',
                data: chartData,
            },
        ],
    };

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    );
};

export default LineChart;
