import * as React from "react";

export function useModalDialogClassName(containerStyle) {
  const myRef = React.createRef();

  React.useEffect(() => {
        let e = myRef.current?.dialog?.querySelector('.modal-content');
        if (e && !e.className.includes(containerStyle))
           e.className = `${e.className} ${containerStyle}`;
  });

  return myRef;
}

export function useDataFetcher(dataFetcher) {
  const [data, setData] = React.useState(undefined);

  if (!data && dataFetcher) {
      async function loadData() {
          try {
            let loadedData = await dataFetcher();
            if (loadedData != data)
                setData(loadedData);
          } catch (err) {

          }
      }
      loadData();
  }

  return data;
}