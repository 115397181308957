import React, { useEffect, useState } from 'react';
import { Account, cairo, CallData, Contract, RpcProvider } from 'starknet';
import contract_abi from "../contract_abi.json";
import trimLeadingZeros from "../common/wallets";
import './AdminMapHeroPanelMintButton.css'; // Import the CSS file

function AdminMapHeroPanelMintButton({ contractAddress, id, token, accountAddress, privateKey }) {
    const [mintAmount, setMintAmount] = useState(1); // State to track the mint amount

    const alert = window.alerts?.alert;
    const success = window.alerts?.success;
    const loading = window.alerts?.loading;
    const loadingStop = window.alerts?.loadingStop;
    const loadingPopup = window.alerts?.loading;
    const loadingStopPopup = window.alerts?.loadingStop;
    const loadingAlertStopPopup = window.alerts?.loadingInAlertStop;

    const tokenBuyBtn = async () => {
        accountAddress = trimLeadingZeros(accountAddress);

        const provider = new RpcProvider({ nodeUrl: String(process.env.REACT_APP_STARKNET_RPC_URL) });
        const account = new Account(provider, accountAddress, privateKey, undefined, "0x3");

        const tokenContract = new Contract(contract_abi, contractAddress, account);

        const idPopup = loadingPopup("Please wait...");

        await tokenContract.get_token_price(id, mintAmount) // Get price for the mint amount
            .then(async (res) => {
                const price = res;
                console.log(price);
                console.log(mintAmount);

                await account.execute([
                    {
                        contractAddress: "0x04718f5a0fc34cc1af16a1cdee98ffb20c31f5cd61d6ab07201858f4287c938d",
                        entrypoint: 'approve',
                        calldata: CallData.compile({
                            spender: contractAddress,
                            amount: cairo.uint256(price),
                        }),
                    },
                    {
                        contractAddress: contractAddress,
                        entrypoint: 'purchase_token',
                        calldata: CallData.compile({
                            id: id,
                            token: token,
                            amount: mintAmount
                        }),
                    },
                ]).then(async (multiCall) => {
                    console.log("tx hash", multiCall.transaction_hash);
                    await provider.waitForTransaction(multiCall.transaction_hash)
                        .catch(e => {
                            loadingAlertStopPopup(idPopup, "Error: " + e.toString().substring(0, 50) + "...");
                            console.log(e);
                        }).finally(() => {
                            loadingStopPopup(idPopup, "Transaction sent. Please wait for confirmation. " + multiCall.transaction_hash);
                        });
                }).catch(e => {
                    loadingAlertStopPopup(idPopup, "Error: " + e.toString().substring(0, 50) + "...");
                    console.log(e);
                });

            })
            .catch(e => {
                console.log(e);
                loadingAlertStopPopup(idPopup, "Error: " + e.toString().substring(0, 50) + "...");
            });

    };

    // Functions to handle counter increment and decrement
    const increment = () => setMintAmount(mintAmount + 1);
    const decrement = () => setMintAmount(mintAmount > 1 ? mintAmount - 1 : 1);

    return (
        <div className={"d-flex flex-row align-content-center"}>
            <button className={"align-self-center adm-btn-circle me-1"} onClick={decrement}>▼</button>
            <button className="btn btn-primary custom-btn-mint" onClick={tokenBuyBtn}>Mint x{mintAmount}</button>
            <button className={"align-self-center adm-btn-circle ms-1"} onClick={increment}>▲</button>
        </div>
    );
}

export default AdminMapHeroPanelMintButton;