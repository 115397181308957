import React, { useEffect, useState } from 'react';
import { connect } from "starknetkit";
import { InjectedConnector } from "starknetkit/injected";
import { cairo, CallData, Contract, provider, WalletAccount } from 'starknet';
import contract_abi from "./contract_abi.json";

function MapSeasonTokenHeroMintButton({ contractAddress, id, token }) {
    const [connection, setConnection] = useState('');
    const [account, setAccount] = useState('');
    const [address, setAddress] = useState('');
    const [mintAmount, setMintAmount] = useState(1); // State to track the mint amount

    const alert = window.alerts?.alert;
    const success = window.alerts?.success;
    const loading = window.alerts?.loading;
    const loadingStop = window.alerts?.loadingStop;

    useEffect(() => {
        const connectToStarknet = async () => {
            const { wallet } = await connect({
                modalMode: "neverAsk",
                dappName: "FORCE PRIME HEROES",
                connectors: [
                    new InjectedConnector({ options: { id: "argentX" } }),
                    new InjectedConnector({ options: { id: "braavos" } })
                ]
            });

            if (wallet && wallet.isConnected) {
                setConnection(wallet);
                setAccount(wallet.account);
                setAddress(wallet.selectedAddress);
            }
        };
        connectToStarknet();
    }, []);

    const connectWallet = async () => {
        const { wallet } = await connect({
            dappName: "FORCE PRIME HEROES",
            connectors: [
                new InjectedConnector({ options: { id: "argentX" } }),
                new InjectedConnector({ options: { id: "braavos" } })
            ]
        });

        if (wallet && wallet.isConnected) {
            setConnection(wallet);
            setAccount(wallet.account);
            setAddress(wallet.selectedAddress);
        }
    };

    const tokenBuyBtn = async () => {
        if (!connection) {
            await connectWallet();
        }
        const { wallet } = await connect({
            modalMode: "neverAsk",
            dappName: "FORCE PRIME HEROES",
            connectors: [
                new InjectedConnector({ options: { id: "argentX" } }),
                new InjectedConnector({ options: { id: "braavos" } })
            ]
        });

        if (wallet && wallet.isConnected) {
            setConnection(wallet);
            setAccount(wallet.account);
            setAddress(wallet.selectedAddress);

            const myWalletAccount = new WalletAccount({ nodeUrl: String(process.env.REACT_APP_STARKNET_RPC_URL) }, wallet);

            const tokenContract = new Contract(contract_abi, contractAddress, myWalletAccount);

            await tokenContract.get_token_price(id, mintAmount) // Get price for the mint amount
                .then(async (res) => {
                    const price = res;
                    console.log(price)
                    console.log(mintAmount)

                    await myWalletAccount.execute([
                        {
                            contractAddress: "0x04718f5a0fc34cc1af16a1cdee98ffb20c31f5cd61d6ab07201858f4287c938d",
                            entrypoint: 'approve',
                            calldata: CallData.compile({
                                spender: contractAddress,
                                amount: cairo.uint256(price),
                            }),
                        },
                        {
                            contractAddress: contractAddress,
                            entrypoint: 'purchase_token',
                            calldata: CallData.compile({
                                id: id,
                                token: token,
                                amount: mintAmount
                            }),
                        },
                    ]).then(async (multiCall) => {
                        await provider.waitForTransaction(multiCall.transaction_hash)
                            .catch(e => {
                                console.log(e);
                            });
                    }).catch(e => {
                        console.log(e);
                    });

                }).catch(e => {
                    console.log(e);
                    alert("Error getting price from chain. Please try again later.");
                });
        }
    };

    // Functions to handle counter increment and decrement
    const increment = () => setMintAmount(mintAmount + 1);
    const decrement = () => setMintAmount(mintAmount > 1 ? mintAmount - 1 : 1);

    return (
        <div className={"d-flex flex-row"}>
            <button className={"fp-btn-bg-small text-black d-none d-sm-block lite"} onClick={decrement}>▼</button>
            <button className="fp-btn-bg-small text-black d-none d-sm-block"
                    onClick={tokenBuyBtn}>Mint x{mintAmount}</button>
            <button className={"fp-btn-bg-small text-black d-none d-sm-block lite"} onClick={increment}>▲</button>
        </div>
    );
}

export default MapSeasonTokenHeroMintButton;
